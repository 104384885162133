import React from "react";
import Modal from "react-modal";
import { Form, Interpretation, ReferenceEquation, Question } from "../Form";
import ReferenceEquationComponent from "./ReferenceEquationComponent";
import TiptapQuestionReference from "./TipTapQuestionReference";

Modal.setAppElement('#root');

export default (props: any) => {
    const [model, setModel] = React.useState<Interpretation[]>(props.value);

    function updateQuestion() {
        props.onValueChange(model);

        if (props.stopEditing)
          props.stopEditing();
    }

    function updateModel(value: Interpretation, index: number) {
        var copy = [...model];

        copy[index] = value;
        
        setModel(copy);
    }

    function updateOutput(value: string, index: number) {
        var copy = [...model];

        copy[index].Output = value;

        setModel(copy);
    }

    function updateReferenceModel(value: ReferenceEquation, parentIndex: number, index: number) {
        var copy = [...model];

        copy[parentIndex].Conditions[index] = value;

        updateModel(copy[parentIndex], parentIndex);
    }

    function queryQuestions(options: any) {
        var query = options?.query;
        var parentForm = (props.form ? props.form : props.colDef.cellEditorParams.parentModel) as Form;
        var questions: Question[] = [];
        
        parentForm.Pages.forEach(element => {
            questions.push(...element.Questions);
        });

        if (query)
            return questions.filter(q => q.Text.toLowerCase().startsWith(query.toLowerCase()));
        
        return questions;
    }

    function removeOutput(index: number) {
      var copy = [...model];

      copy.splice(index, 1);

      setModel(copy);
    }

    function renderEquations() : JSX.Element[] {
      var elements: JSX.Element[] = [];

      for (let i = 0; i < model.length; i++) {
        elements.push(<div key={`Conditions${i}`}><u>Conditions</u> <button type="button" className="btn btn-sm btn-primary" onClick={() => addCondition(i)}>Add Condition</button></div>);

        for (let j = 0; j < model[i].Conditions.length; j++) {
          elements.push(<ReferenceEquationComponent
            key={`RefEq${i}-${j}`}
            questionResource={queryQuestions}
            value={model[i].Conditions[j]}
            setValue={(newValue: ReferenceEquation) => updateReferenceModel(newValue, i, j)}
            remove={() => removeEquation(i, j)}
          />);
        }

        elements.push(<TiptapQuestionReference key={`TipTap${i}`} itemResource={queryQuestions} value={model[i].Output} setValue={(v: string) => updateOutput(v, i)} remove={() => removeOutput(i)} />)
      }

      return elements;
    }

    function removeEquation(parentIndex: number, index: number) {
        var copy = [...model];

        copy[parentIndex].Conditions.splice(index, 1);

        setModel(copy);
    }

    function addCondition(index: number) {
        var copy = [...model];

        copy[index].Conditions.push(new ReferenceEquation("", ""));

        setModel(copy);
    }

    function addEquation() {
      var copy = [...model, new Interpretation("", [])];

      setModel(copy);
    }

    return (
        <div>
            <Modal
                isOpen={true}
                onRequestClose={updateQuestion}
                contentLabel="Example Modal"
                portalClassName="BuilderModal Interpretations"
            >
                <div className="BuilderModalHeader">Interpretation Settings: {props.data?.Text}</div>
                <div className="BuilderModalContent">
                    {renderEquations()}

                    <button type="button" className="btn btn-sm btn-primary" style={{ margin: 4 }} onClick={addEquation}>Add Output</button>
                    <button type="button" className="btn btn-sm btn-primary" style={{ margin: 4 }} onClick={updateQuestion}>Save</button>
                </div>
            </Modal>
        </div>
    );
}