import React from "react";
import { Validations } from "../Form";

interface ValidationEquationElement {
    value: Validations,
    questionResource: (options?: any) => any[],
    setValue: (newValue: Validations) => void,
    remove: () => void
}

export default (props: ValidationEquationElement) => {
    function updateModel(value: string) {
        if (Object.values(Validations).includes(value as Validations)) {
            props.setValue(value as Validations);
        }
    }

    function getValidationsOptions() : JSX.Element[] {
        var keys = Object.keys(Validations) as Array<string>

        var options = keys.map((key: string, index: number, array: string[]) => {
            var thinnedValue = key.replaceAll('_', ' ');

            return <option key={`ValidationOption_${index}`} value={key}>{thinnedValue}</option>
        });

        return options;
    }

    return (
        <div className="ReferenceEquation">
            <button className="bi-dash-circle danger btn btn-sm btn-danger" onClick={props.remove}></button>

            <select value={props.value} onChange={v => updateModel(v.target.value)}>
                { getValidationsOptions() }
            </select>
        </div>
    );
}