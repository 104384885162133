import React from 'react';
import { toastPromise } from '../Helpers/ToastMessage';
import 'react-image-upload/dist/index.css';
import { useCompany } from '../Store/storeFunctions';
import { getFunctions, httpsCallable } from "firebase/functions";

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormikTextAreaInput, FormikTextInput } from '../FormikFields';

const functions = getFunctions();
const requestChangeThroughJira = httpsCallable(functions, "requestChangeThroughJira");

function RequestChange(props) {
  const [change, setChange] = React.useState({
    form: '',
    description: ''
  });
  const company = useCompany();

  const OptionsSchema = Yup.object().shape({
    form: Yup.string().required('Required'),
    description: Yup.string().required('Required')
  });

  function saveOptions(change){
    toastPromise(async () => {
      requestChangeThroughJira({
        company: company.companyData?.code,
        change: change
      })
    });
  }

  function render() {
    return (
      <div style={{ backgroundColor: '#F3F9FF', paddingTop: '2vh' }}>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div style={{ paddingLeft: 10 }}>
                <b>Enter details about what changes you need applied to your forms below.</b><br/>
                <b>The more details you can provide, the better.</b>
              </div>
              <Formik
                initialValues={change}
                validationSchema={OptionsSchema}
                onSubmit={values => {
                  // same shape as initial values
                  setChange(values);
                  saveOptions(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Field errors={errors} name="form" component={FormikTextInput} formiklabel="Form" />
                    <Field errors={errors} name="description" component={FormikTextAreaInput} formiklabel="Description" />

                    <div className="mb-3 center"> 
                      <button className="btn btn-sm btn-primary" type="submit">Save</button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className='col-6'>
              <img src="../assets/tax icons.png" style={styles.taxicons} width={500}></img>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return render();
}

const styles = {
  taxicons: {
    width: '100%',
    marginTop: '20%'
  }
}

export default RequestChange;