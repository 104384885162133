export const validateRequired = (value) => {
    if (!value) {
        return "Required";
    }
    
    return undefined;
};

export const validateEmail = (value) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        return 'Invalid email address';
    }

    return undefined;
};

export const validateSsn = (value) => {
    if (!/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/m.test(value)) {
        return 'Invalid SSN';
    }

    return undefined;
};

export const validatePhone = (value) => {
    if (!/^(\+[0-9]{1,2})?[ -]?([0-9]{3}|\([0-9]{3}\))[ -]?[0-9]{3}[ -]?[0-9]{4}$/m.test(value)) {
        return 'Invalid Phone';
    }

    return undefined;
};

export const validateNumber = (value) => {
    if (!/^-?[0-9]+(\.[0-9]+)?$/m.test(value)) {
        return 'Invalid Number';
    }

    return undefined;
};

export const validateYear = (value) => {
  if (!/^[0-9]{4}$/m.test(value)) {
    return 'Invalid Year';
  }

  return undefined;
};