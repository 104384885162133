import React from "react";
import Modal from "react-modal";
import { ReferenceEquation, Form, Question } from "../Form";
import ReferenceEquationComponent from "./ReferenceEquationComponent";

Modal.setAppElement('#root');

export default (props: any) => {
    const [model, setModel] = React.useState<ReferenceEquation[]>(props.value);

    function updateQuestion() {
        props.onValueChange(model);
        props.stopEditing();
    }

    function updateModel(value: ReferenceEquation, index: number) {
        var copy = [...model];

        copy[index] = value;
        
        setModel(copy);
    }

    function queryQuestions(options: any) {
        var query = options?.query;
        var parentForm = props.colDef.cellEditorParams.parentModel as Form;
        var questions: Question[] = [];
        
        parentForm.Pages.forEach(element => {
            questions.push(...element.Questions);
        });

        if (query)
            return questions.filter(q => q.Text.toLowerCase().startsWith(query.toLowerCase()));

        return questions;
    }

    function renderEquations() : JSX.Element[] {
        var elements: JSX.Element[] = [];

        for (let i = 0; i < model.length; i++) {
            elements.push(<ReferenceEquationComponent
                key={`RefEqComp_${i}`}
                value={model[i]}
                questionResource={queryQuestions}
                setValue={(newValue: ReferenceEquation) => updateModel(newValue, i)}
                remove={() => removeEquation(i)}
            />);
        }

        return elements;
    }

    function removeEquation(index: number) {
        var copy = [...model];

        copy.splice(index, 1);

        setModel(copy);
    }

    function addEquation() {
        var copy = [...model, new ReferenceEquation("", "")];

        setModel(copy);
    }

    return (
        <div>
            <Modal
                isOpen={true}
                onRequestClose={updateQuestion}
                contentLabel="Example Modal"
                portalClassName="BuilderModal Required"
            >
                <div className="BuilderModalHeader">Requirement Settings: {props.data.Text}</div>
                <div className="BuilderModalContent">
                    <button type="button" className="btn btn-sm btn-primary" style={{ margin: 4 }} onClick={addEquation}>Add</button>

                    {renderEquations()}

                    <button type="button" className="btn btn-sm btn-primary" style={{ margin: 4 }} onClick={updateQuestion}>Save</button>
                </div>
            </Modal>
        </div>
    );
}