import React from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";
import { toastPromise } from '../Helpers/ToastMessage';
import Nav from '../Nav';

const functions = getFunctions();
const getSubmissionDocuments = httpsCallable(functions, "getSubmissionDocuments");

function ChecklistView() {
  const { fi, id } = useParams();
  const [documents, setDocuments] = React.useState();

  React.useEffect(() => {
    if (fi && id && !documents) {
      toastPromise(async ()=>{
        var res = await getSubmissionDocuments({
          submissionID: id,
          formID: fi
        });

        if (res.data && res.data.Success) {
          setDocuments(res.data.Documents);
        } else {
          throw new Error("Unable to fetch documents.");
        }
      }, true);
    }
  }, [fi, id, documents]);

  function renderDocuments() {
    if (!id || !documents) { //signed in
      return null;
    } else { //not signed in
      return <div className="container">
        <h2>Tax Checklist Required Documents</h2>

        <div className="container">
          {
            documents.map((v, i) => {
              return <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id={`checklistItem${i}`} />
                <label className="form-check-label" style={{ marginLeft: 10 }} htmlFor={`checklistItem${i}`}>{v}</label>
              </div>
            })
          }

        </div>
      </div>;
    }
  }

  function render() {
    return <div>
      <Nav companyName="Brickhouse Financial"/>
      <div style={{ marginTop: 75 }}>
        {renderDocuments()}
      </div>
    </div>
  }

  return render();
}

export default ChecklistView;