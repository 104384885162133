export function getMimeType(filename) {
  if (!filename)
    return null;

  if (!filename.includes('.'))
    return null;

  const extension = filename.substring(filename.lastIndexOf("."));

  switch (extension) {
    case ".gif": return 'image/gif';
    case ".bmp": return 'image/bmp';
    case ".jpeg": return 'image/jpeg';
    case ".jpg": return 'image/jpeg';
    case ".png": return 'image/png';
    case ".svg": return 'image/svg+xml';
    case ".tif": return 'image/tiff';
    case ".tiff": return 'image/tiff';
    case ".webp": return 'image/webp';
    default: return null;
  }
}