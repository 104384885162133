import React from "react";
import { useParams } from "react-router-dom";
import { initializeApp } from '@firebase/app';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import * as CompanyData from "../Helpers/CompanyData.ts";
import { getFunctions, httpsCallable } from "firebase/functions";
import { BeatLoader } from 'react-spinners';

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});
const functions = getFunctions();

const getSubmission = httpsCallable(functions, "getSubmission");
const setSubmitted = httpsCallable(functions, "docsSubmitted");
const storage = getStorage(app, process.env.REACT_APP_FIREBASE_STORAGE_BUCKET);

function FormResult() {
  // companyid, formid, submissionid for viewing status/docs.
  const { ci, fid, sid } = useParams();

  const [processing, setProcessing] = React.useState(true);
  const [submission, setSubmission] = React.useState(undefined);
  const [companyData, setCompanyData] = React.useState(undefined);
  const [imageSource, setImageSource] = React.useState();

  React.useEffect(() => {
      if (companyData?.logo) {
          const logoRef = ref(storage, companyData.logo);

          getDownloadURL(logoRef).then(url => {
              setImageSource(url);
          });
      }

  }, [companyData]);

  const gatherInterpolationData = React.useCallback(async function() {
    var data = await CompanyData.getInterpolationData(ci);

    return data;
  }, [ci]);

  const initiateFormModel = React.useCallback(async function () {
    if (!submission && ci && fid && sid) {
      var data = await getSubmission({ CompanyID: ci, FormID: fid, SubmissionID: sid });
      var interpolationValues = await gatherInterpolationData();
      
      setSubmission(data.data);
      setCompanyData(interpolationValues);
      setProcessing(false);
    }
  }, [ci, fid, sid, gatherInterpolationData, getSubmission]);

  const updateSubmitted = function() {
    var data = { ...submission, Status: "Submitted" };
    setSubmission(data);
  }

  React.useEffect(() => {
    // on startup of this page, initiate pages.
    initiateFormModel();
  }, [initiateFormModel, sid]);

  function renderStatusBar() {
    if (!submission.UsesStatusFeature) {
      return null; // Not using this feature so hide it.
    }

    const statusOptions = [
      {
        type: "SubmitDocs",
        text: "Submit Docs",
        icon: ""
      },
      {
        type: "Submitted",
        text: "Submitted",
        icon: ""
      },
      {
        type: "Preparing",
        text: "Preparing",
        icon: ""
      },
      {
        type: "Review",
        text: "Review",
        icon: ""
      },
      {
        type: "Filing",
        text: "Filing",
        icon: ""
      },
      {
        type: "Complete",
        text: "Complete",
        icon: ""
      },
    ];

    var styles = {
      statusBar: {
        marginBottom: 8,
        display: "flex",
        flexDirection: 'row',
        justifyContent: "stretch",
        flexWrap: 'wrap',
        alignItems: "center",
        width: '100%'
      },
      statusBarItem: {
        height: 60,
        minWidth: 150,
        position: 'relative',
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        flex: 1
      },
      statusBarItemBar: {
        backgroundColor: 'var(--green)',
        height: 5,
        width: '102%',
        position: 'absolute',
        left: '1%',
        borderRadius: 5
      },
      statusBarItemBarArrow: {
        borderLeftWidth: 20,
        borderBottomWidth: 10,
        borderTopWidth: 10,
        borderRightWidth: 10,
        borderStyle: 'solid',
        borderLeftColor: "var(--green)",
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        position: 'absolute',
        right: -20,
        zIndex: 20
      },
      statusBarItemEllipse: {
        backgroundColor: 'var(--green)',
        position: 'absolute',
        margin: 8,
        paddingTop: 4,
        paddingBottom: 4,
        paddingRight: 8,
        paddingLeft: 8,
        borderRadius: 15,
        textAlign: 'center',
        zIndex: 10
      }
    }

    var foundStep = false;

    var stages = statusOptions.map((s, i) => {
      if (foundStep) {
        return <div style={styles.statusBarItem} key={i}>
          <div style={{ ...styles.statusBarItemBar, backgroundColor: 'lightgrey' }}></div>
          <div style={{ ...styles.statusBarItemEllipse, backgroundColor: 'lightgrey' }}>{s.text}</div>
        </div>
      } else if (s.type == submission.Status && i != statusOptions.length - 1) {
        foundStep = true;
        return <div style={styles.statusBarItem} key={i}>
          <div style={styles.statusBarItemBar}></div>
          <div style={styles.statusBarItemBarArrow}></div>
          <div style={styles.statusBarItemEllipse}>{s.text}</div>
        </div>
      } else {
        return <div style={styles.statusBarItem} key={i}>
          <div style={styles.statusBarItemBar}></div>
          <div style={styles.statusBarItemEllipse}>{s.text}</div>
        </div>
      }
    })

    return (
      <div style={styles.statusBar}>
        {stages} 
      </div>
    );
  }

  function renderDocuments() {
    return (
      <div className="card-body">
        <div className="center">
          
          <div style={{ fontSize: "xx-large", fontWeight: 'bold' }}>
            Here is your Tax Checklist!
          </div>

          <br />

          { renderStatusBar() }
          
          {
            submission.Status != "SubmitDocs" ? null :
            <p>Provide <strong><i>ALL</i></strong> these documents so we can prepare your return.</p>
          }

          {
            submission.Status != "Complete" ? null :
            <h3>You're all done!</h3>
          }

          <div style={{ fontSize: 40 }}>
            <span title={`Upload to ${companyData?.interpolationData.CompanyPortal}`} style={{ marginLeft: 20, marginRight: 20, cursor: 'pointer' }} className="bi-cloud-upload" onClick={() => {
              window.open(companyData?.interpolationData.CompanyPortal, '_blank').focus();
            }}></span>
            <span title={companyData?.interpolationData.CompanyAddress} style={{ marginLeft: 20, marginRight: 20, cursor: 'pointer' }} className="bi-car-front"></span>
            <span title={companyData?.interpolationData.CompanyEmail} style={{ marginLeft: 20, marginRight: 20, cursor: 'pointer' }} className="bi-envelope"></span>
            <span title="Print Checklist" style={{ float: "right", cursor: 'pointer' }} className="bi-printer" onClick={() => {
              window.print();
            }}></span>
          </div>
        </div>

        {
          !submission.Documents || !submission.Documents.length ? <></> : <>
            <hr ></hr>
            {
              submission.Documents.map((d, i) => {
                return <div key={i} style={{ margin: 5, marginLeft: 45 }}>
                  <input name={`results${i}`} id={`results${i}`} type="checkbox" style={{ marginRight: 5, marginLeft: -20 }}>
                  </input>

                  <label style={{ display: 'inline' }} htmlFor={`results${i}`} dangerouslySetInnerHTML={{ __html: d }}></label>
                </div>;
              })
            }
          </>
        }

        <hr></hr>

        <div className="center">
          {
            submission.Status != "SubmitDocs" || !submission.UsesStatusFeature ? null :
            <button className={`btn btn-success`}
              title="Click here to inform your preparer that you have provided them all of your documents."
              type="button"
              onClick={() => {
                var confirmed = confirm("Are you sure? This will notify your tax preparer that they have access to all of your documents and can begin preparing your return.");

                if (confirmed) {
                  setSubmitted({
                    CompanyID: ci,
                    FormID: fid,
                    SubmissionID: sid
                  });
                  updateSubmitted(true);  
                }
              }}
              style={{
                margin: 10,
                padding: 15,
                height: 'auto',
                width: 250,
                borderWidth: 3,
                borderStyle: 'solid',
                borderColor: 'var(--green)',
                fontWeight: "400"
              }}>I have submitted <b><i>ALL</i></b> my documents</button>
          }
          <br/>
          <i>This checklist has also been emailed to you.</i>
        </div>
      </div>
    )
  }

  function render() {
    if (processing) {
      return (<div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div>);
    }

    var page = null;

    if (submission) {
      page = renderDocuments();
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "2%",
          backgroundColor: companyData?.colors?.background ? companyData?.colors?.background : "#FFF",
          height: "100%",
          width: "100%"
        }}
        >
        {
          !imageSource ? null : 
          <img style={{ height: 150, marginBottom: '2%', margin: 'auto' }} alt="" src={imageSource} />
        }

        <div
          className="card formTool"
          style={{
            width: "75%",
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: companyData?.colors?.accent ? companyData?.colors?.accent : "var(--bs-light-blue)",
            height: '100%',
            margin: 'auto'
          }}
          >
          { page }
        </div>
      </div>
    );
  }

  return render();
}

export default FormResult;
