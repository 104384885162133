import React from 'react';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";
import { useCompany, useUser } from '../../Store/storeFunctions';
import { getFunctions, httpsCallable } from "firebase/functions";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { BeatLoader } from 'react-spinners';
import {
    FormikEmailInput,
    FormikPasswordInput,
    FormikTextInput
} from '../../FormikFields';

const auth = getAuth();

const functions = getFunctions();
const createAccountSelfService = httpsCallable(functions, "createAccountSelfService");

function SelfServiceCreateAccount(props) {
    const user = useUser();
    const company = useCompany();
    const [validationMessage, setValidationMessage] = React.useState();
    const [formFields, setFormFields] = React.useState({
      companyName: "",
      companyEmail: "",
      companyPhone: "",
      companyWebsite: "",
      companyPortal: "",
      companyAddress: "",
      name: "",
      email: "",
      password: "",
      confirmPassword: ""
    });
    const [loading, setLoading] = React.useState();

    const formSchema = Yup.object().shape({
        // company settings
        companyName: Yup.string().required('Required'),
        companyEmail: Yup.string().email("Invalid Email Address").required('Required'),
        companyPhone: Yup.string().required('Required'),
        companyWebsite: Yup.string(),
        companyPortal: Yup.string(),
        companyAddress: Yup.string().required('Required'),
        // account settings
        name: Yup.string().required('Required'),
        email: Yup.string().required('Required'),
        password: Yup.string().required('Required'),
        confirmPassword: Yup.string().required('Required')
    });

    async function createAccount(data) {
        setLoading(true);

        if (!data.companyName) {
            setValidationMessage("Company Name is required.");
            return;
        } else if (!data.email) {
            setValidationMessage("Email is required.");
            return;
        } else if (!data.password) {
            setValidationMessage("Password is required and must match the confirmed password.");
            return;
        } else if (!data.name) {
            setValidationMessage("Name is required.");
            return;
        } else if (data.password !== data.confirmPassword) {
            setValidationMessage("Passwords do not match.");
            return;
        }

        await setPersistence(auth, browserSessionPersistence)
            .then(() => {
                return createUserWithEmailAndPassword(auth, data.email, data.password)
            })
            .then(async (cred) => {
                user.set(cred.user);
                return await createAccountSelfService(data);
            })
            .then(async (newAccount) => {
                //user is now authenticated and ready to purchase a product package.
                company.setCode(newAccount.data.companyCode);
                return newAccount.data.companyCode;
            })
            .then(async (newCode) => {
                //user is now authenticated and ready to purchase a product package.
                await company.loadCompany(newCode).then(company.loadAdmins(newCode));
                window.location = "/Admin/" + newCode;
            })
            .catch((err) => {
                if (err.code == "auth/email-already-in-use")
                    setValidationMessage("Email already linked to an account.");
                else
                    setValidationMessage("Error creating account, please try again later.");

                console.error(err);
            });
        
        setLoading(false);
    }

    function renderFormikForm() {
        return <div className="container">
            <Formik
                initialValues={formFields}
                validationSchema={formSchema}
                onSubmit={values => {
                    // same shape as initial values
                    setFormFields(values);
                    createAccount(values);
                }}
            >
                {({ errors, touched }) => (
                    <Form>                      
                      <Field errors={errors} name="companyName" component={FormikTextInput} formiklabel="Company Name" />
                      <Field errors={errors} name="companyEmail" component={FormikEmailInput} style={{ width: '100%' }} formiklabel="Company Email" />
                      <Field errors={errors} name="companyPhone" component={FormikTextInput} formiklabel="Company Phone" />
                      <Field errors={errors} name="companyWebsite" component={FormikTextInput} formiklabel="Company Website" />
                      <Field errors={errors} name="companyPortal" component={FormikTextInput} formiklabel="Document Portal" />
                      <Field errors={errors} name="companyAddress" component={FormikTextInput} formiklabel="Company Address" />

                      <hr style={{ marginTop: 35, marginBottom: 30 }} />

                      <Field errors={errors} name="name" component={FormikTextInput} formiklabel="Full Name" />
                      <Field errors={errors} name="email" component={FormikEmailInput} style={{ width: '100%' }} formiklabel="Personal Email Address" />
                      <Field errors={errors} name="password" component={FormikPasswordInput} formiklabel="Password" />
                      <Field errors={errors} name="confirmPassword" component={FormikPasswordInput} formiklabel="Confirm Password" />

                      <div className="mb-3 center">
                        {
                          loading ? <BeatLoader color={"#045AA1"} />
                          : <button className="btn btn-sm btn-primary" type="submit">Sign Up</button>
                        }
                      </div>
                    </Form>
                )}
            </Formik>
        </div>;
    }

    function render() {
        if (user.user && company.companyData?.code) { //signed in
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%' }}>
                    <div className="card" style={{ 
                        minWidth: "724px",
                        paddingLeft: 10,
                        paddingRight: 10,
                        backgroundColor: 'var(--bs-light-blue)'
                    }}>
                        <div className="card-body">
                            <div className="card-title text-center" style={{ color: 'var(--bs-primary)', fontSize: '36px', lineHeight: '49px', fontWeight: 'bolder' }}>Account Subscription</div>
                            <div className="card-sub-title text-center" style={{ marginBottom: '43px', }}>This is a paid application. Please subscribe below in order to gain access to our features!</div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%' }}>
                    <div className="card" style={{ 
                        minWidth: "724px",
                        paddingLeft: 10,
                        paddingRight: 10,
                        backgroundColor: 'var(--bs-light-blue)'
                    }}>
                        <div className="card-body">
                            <div className="card-title text-center" style={{ color: 'var(--bs-primary)', fontSize: '36px', lineHeight: '49px', fontWeight: 'bolder' }}>Account Creation</div>
                            {validationMessage ? <div className="center" style={{ color: 'var(--red)' }}>{validationMessage}</div> : null}
                            {renderFormikForm()}
                        </div>
                    </div>
                </div>
            );
        }
    }

    return render();
}

export default SelfServiceCreateAccount;