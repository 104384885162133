import React from 'react';
import { initializeApp } from '@firebase/app';
import { getFirestore, doc, getDoc } from '@firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { toastPromise } from '../../Helpers/ToastMessage';
import { useCompany, useUser } from '../../Store/storeFunctions';
import { BeatLoader } from 'react-spinners';

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const functions = getFunctions();
const db = getFirestore(app);
const getAccountSession = httpsCallable(functions, "getAccountSession");
const initiateSubscriptionSession = httpsCallable(functions, "initiateSubscriptionSession");
const getAccountSubscription = httpsCallable(functions, "getAccountSubscription");

const products = [
  {
    type: "BASIC",
    price: 600,
    pricePerUnit: 6,
    threshold: 100,
    frequency: 'yr',
    title: "Basic",
    description: "For 10-300 estimated submissions. $6 per submission."
  },
  {
    type: "PRO",
    price: 2000,
    pricePerUnit: 4,
    threshold: 500,
    frequency: 'yr',
    title: "Professional",
    description: "For 300-750 estimated submissions. $4 per submission."
  },
  {
    type: "ENTERPRISE",
    price: 3000,
    pricePerUnit: 3,
    threshold: 1000,
    frequency: 'yr',
    title: "Enterprise",
    description: "For 750+ estimated submissions. $3 per submission.",
    featured: true
  }
];

const steps = [
  { Name: "Configure Email Templates", Id: 3 }
]

function AccountSettings(props) {
  const [loading, setLoading] = React.useState();
  const [screen, setScreen] = React.useState("General");
  const [subscription, setSubscription] = React.useState();

  const company = useCompany();
  const user = useUser();

  React.useEffect(() => {
    if (company.companyData.stripeSubscription) {
      setLoading(true);
      getAccountSubscription({
        subscription: company.companyData.stripeSubscription
      }).then((sub) => {
        setSubscription(sub);
        setLoading(false);
      }).catch(err => {
        console.error(err);
        setLoading(false);
      });
    }
  }, [company.companyData.stripeSubscription]);

  async function manageSubscription() {
    setLoading(true);
    var url = await getAccountSession({ company: company.companyData?.code });
    setLoading(false);
    window.open(url.data, "_blank");
  }

  async function subscribe(product) {
    var session = await initiateSubscriptionSession({
      company: company.companyData?.code,
      email: user.user.email,
      product: product
    });

    return session?.data?.url;
  }

  function renderProducts() {
    return products.map(p => {
      return <div style={styles.product}>
        {p.featured ? <div style={styles.productFeatured}>Best deal</div> : null }
        <div style={styles.productTitle}>{p.title}</div>
        <div style={styles.productDescription}>{p.description}</div>
        <div style={styles.productPrice}>
          ${p.price}/
          <div style={styles.productFrequency}>
            {p.frequency} for {p.threshold} subs
          </div>
        </div>

        <div style={styles.productPricePerUnit}>
          ${p.pricePerUnit}&nbsp;
          <div style={styles.productFrequency}>
            /sub after {p.threshold} billed per {p.threshold * 0.1}
          </div>
        </div>
        
        <div style={{ ...styles.productPrice, marginTop: 25 }}>
          $500
          <div style={styles.productFrequency}>
            &nbsp;Implementation
          </div>
        </div>

        <div style={styles.subscribeButton}>
          <button className="btn btn-sm btn-primary" style={{width: '100%', height: '100%'}} onClick={async () => {
            var url = await subscribe(p.type);
            window.open(url, '_blank');
          }}>Subscribe</button>
        </div>

      </div>
    })
  }

  function renderChosenSubscription() {
    if (!subscription) {
      return <><button className="btn btn-sm btn-primary" style={{width: '100%' }} onClick={manageSubscription}>Manage Subscription</button></>;
    }

    var product = products.find(p => p.type == subscription.type);

    if (!product) {
      return <><button className="btn btn-sm btn-primary" style={{width: '100%' }} onClick={manageSubscription}>Manage Subscription</button></>;
    }
    
    var end_date = new Date();
    end_date.setTime(subscription.current_period_end * 1000);
    
    return <div style={styles.productCurrent}>
      <div style={styles.productFeaturedCurrent}>Current Plan</div>
      <div style={styles.productTitleCurrent}>{product.title}</div>
      <div style={styles.productDescriptionCurrent}>{product.description}</div>
      <div style={styles.productPriceCurrent}>
        ${product.price}/
        <div style={styles.productFrequencyCurrent}>
          {product.frequency} for {product.threshold} subs
        </div>
      </div>

      <div style={styles.productPricePerUnitCurrent}>
        ${product.pricePerUnit}&nbsp;
        <div style={styles.productFrequencyCurrent}>
          /sub after {product.threshold} billed per {product.threshold * 0.1}
        </div>
      </div>

      <hr />

      <div style={{ ...styles.productDescriptionCurrent, textAlign: 'center', opacity: 1 }}>{ subscription.status == 'active' ? "Renews" : "Ends" } {end_date.toLocaleDateString()}</div>

      <hr />
      
      <button className="btn btn-sm btn-primary" style={{width: '100%', height: '100%'}} onClick={manageSubscription}>Manage Subscription</button>
    </div>
  }

  function renderGeneralButton() {
    if (screen == "General") {
      return <div style={styles.menu}><b>General</b></div>;
    }

    return <div>
      <div style={styles.menuButton} onClick={() => setScreen("General")}>General</div>
    </div>
  }

  function renderGeneralBody() {
    if (screen !== "General")
      return null;

    if (!company.companyData.stripeCustomerId || !company.companyData.isSubscribed)
      return renderProducts();
    else {
      return <div>
        { renderChosenSubscription() }
      </div>;
    }
  }

  function renderGetStartedButton() {
    if (screen == "Start") {
      return <div style={styles.menu}><b>Get Started</b></div>;
    }

    return <div>
      <div style={styles.menuButton} onClick={() => setScreen("Start")}>Get Started</div>
    </div>
  }

  function renderGetStartedBody() {
    if (screen !== "Start") {
      return null;
    }

    return <div className="accordion" id="accordionSetupSteps">
      <div className="accordion-item">
        <h1 className="accordion-header" id={`heading1`}>
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse1`} aria-expanded="true" aria-controls={`collapse1`}>
            <h3>#1 Subscribe to one of our plans</h3>
          </button>
        </h1>
        <div id={`collapse1`} className="accordion-collapse collapse" aria-labelledby={`heading1`} data-bs-parent="#accordionSetupSteps">
          <div className="accordion-body" style={{ fontSize: "1.25rem" }}>
            Our service is a paid service and requires an active subscription. There are 3 plans to choose from. Go to General settings to choose a plan or manage your current subscription.
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h1 className="accordion-header" id={`heading4`}>
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse4`} aria-expanded="true" aria-controls={`collapse4`}>
            <h3>#2 Configure Email Templates</h3>
          </button>
        </h1>
        <div id={`collapse4`} className="accordion-collapse collapse" aria-labelledby={`heading4`} data-bs-parent="#accordionSetupSteps">
          <div className="accordion-body" style={{ fontSize: "1.25rem" }}>
            Navigate to the Form Selector page and verify that any form that you intend to use says YES in the Email Template column. If it says NO, check the box on that row and click 'Edit Form Properties'. Alter the template to your desires. There are helpful tips on that page to assist you in adding your logo and referencing questions from your forms. When you are done click Save at the bottom right of the page.
            <div style={{ borderRadius: 10, borderWidth: 1, borderColor: '#045AA1', borderStyle: 'solid', width: '80%', height: 800, padding: 20 }}>
              <iframe src="https://scribehow.com/embed/Managing_Form_Properties_in_TaxChecklist__ZNtK23Z6ROCt6r_OaFcmtQ?skipIntro=true" width="100%" height="100%" allowfullscreen frameBorder="0"></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h1 className="accordion-header" id={`heading2`}>
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse2`} aria-expanded="true" aria-controls={`collapse2`}>
            <h3>Optional: Configure Outgoing Email</h3>
          </button>
        </h1>
        <div id={`collapse2`} className="accordion-collapse collapse" aria-labelledby={`heading2`} data-bs-parent="#accordionSetupSteps">
          <div className="accordion-body" style={{ fontSize: "1.25rem" }}>
            A large benefit to using our software is that you are in control of your outgoing email. If you would like the emails to be sent from your own accout, then you need to give us permissions to send the checklists on your behalf. <br /><br />

            <b>How does it work?</b> - Our service has integrated with Gmail to give you the security and reliability everyone is familiar with. When your client submits one of your forms, our application will tell your Gmail account to send an email to your client. This email will follow your configured email template and will include their required documents based on their form answers.<br /><br />

            <b>What do you need to do?</b> - We need two pieces of information in order to provide this service. A Gmail email address, and a Gmail <i>App Password</i>. Enter these things into the Company Options page. <br /> <br />
            
            <ol>
              <li><b>Gmail email address:</b> This will be an account that you control and plan to use for client interaction. (i.e. checklist@yourfirm.com)</li>
              <li>
                <b>App Password:</b> An <i>App Password</i> is a Gmail generated password that allows applications to send emails on your behalf. There are a few steps to do this. The steps here will help get you there and set up the proper security for your Gmail account.
                <div style={{ borderRadius: 10, borderWidth: 1, borderColor: '#045AA1', borderStyle: 'solid', width: '80%', height: 800, padding: 20 }}>
                  <iframe src="https://scribehow.com/embed/Google_Workflow__uluCj7NCRRO2RrKtbBaHog?skipIntro=true" width="100%" height="100%" allowfullscreen frameBorder="0"></iframe>
                </div>
              </li>
            </ol>

            That's it! Your outgoing email is now set up an ready.
          </div>
        </div>
      </div>
    </div>;
  }

  function render() {
    if (loading) {
      return (<div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div>);
    }

    return (
        <div style={styles.settingsContainer}>
            <div style={{width: '10%'}}>
                {renderGeneralButton()}
                {renderGetStartedButton()}
            </div>
            <div style={{ width: '90%', display: 'flex', justifyContent: 'center' }}>
              {renderGeneralBody()}
              {renderGetStartedBody()}
            </div>
        </div>
    );
  }

  return render();
}

const styles = {
    settingsContainer: {
      fontFamily: 'Open Sans',
      fontSize: 26,
      fontWeight: 'Regular',
      color: '#1F1F1F',
      width: '100%',
      paddingLeft: '10%',
      display: 'flex',
      paddingTop: '2vh'
    },
    product: {
      display: 'inline-block',
      position: 'relative',
      width: 350,
      height: 450,
      borderRadius: 10,
      backgroundColor: 'hsla(0,0%,10%,.05)',
      margin: 20,
      padding: 50
    },
    productDescription: {
      fontWeight: 200,
      opacity: .6,
      fontSize: 16,
      marginBottom: 25
    },
    productTitle: {
      fontWeight: 600
    },
    subscribeButton: {
      height: 40,
      position: 'absolute',
      bottom: 30,
      width: '60%',
      left: '20%',
      borderRadius: 10
    },
    productPrice: {
      fontSize: 30,
      fontWeight: 700
    },
    productFrequency: {
      display: 'inline',
      fontSize: 16,
      fontWeight: 300,
      opacity: .6
    },
    productPricePerUnit: {
      fontSize: 20,
      fontWeight: 500
    },
    productFeatured: {
      fontSize: 15,
      backgroundColor: '#FFF',
      display: 'inline-block',
      margin: 0,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 5,
      position: 'absolute',
      top: 20,
      right: 20,
      color: '#045aa1',
      borderWidth: 1,
      borderColor: '#045aa1',
      borderStyle: 'solid'
    },
    menu: {
      color: '#045AA1',
      marginBottom: 20
    },
    menuButton: {
      color: '#1F1F1F',
      marginBottom: 20,
      cursor: 'pointer'
    },
    productCurrent: {
      display: 'inline-block',
      position: 'relative',
      width: 450,
      borderRadius: 10,
      backgroundColor: 'hsla(0,0%,10%,.05)',
      margin: 20,
      padding: 50
    },
    productDescriptionCurrent: {
      fontWeight: 200,
      opacity: .6,
      fontSize: 16,
      marginBottom: 25
    },
    productTitleCurrent: {
      fontWeight: 600,
      fontSize: 36
    },
    productPriceCurrent: {
      fontSize: 30,
      fontWeight: 700
    },
    productFrequencyCurrent: {
      display: 'inline',
      fontSize: 16,
      fontWeight: 300,
      opacity: .6
    },
    productPricePerUnitCurrent: {
      fontSize: 20,
      fontWeight: 500
    },
    productFeaturedCurrent: {
      fontSize: 15,
      backgroundColor: '#FFF',
      display: 'inline-block',
      margin: 0,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 5,
      position: 'absolute',
      top: 20,
      right: 20,
      color: '#045aa1',
      borderWidth: 1,
      borderColor: '#045aa1',
      borderStyle: 'solid'
    },
};

export default AccountSettings;