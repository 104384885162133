import { initializeApp } from '@firebase/app';
import { getFirestore, getDoc, doc, query, collection, getDocs } from '@firebase/firestore';
import { toastPromise } from '../Helpers/ToastMessage';

const app = initializeApp({
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  });
  
const db = getFirestore(app);

const COMPANY_PATH = 'BusinessRules';

export async function getInterpolationData(company: string): Promise<any> {
    return await toastPromise(
        async ()=>{
            // grab system level forms.
            var rtnObj = {
              colors: {},
              logo: "",
              extraFeatures: {
                logoSize: 150,
              },
              interpolationData: {}
            };
            var ref = await getDoc(doc(db, `${COMPANY_PATH}/${company}`));

            if (ref && ref.exists()) {
                var data = ref.data();

                if (data.interpolationData) {
                  rtnObj.interpolationData = data.interpolationData;
                }

                rtnObj.colors = {
                  background: data.primaryColor,
                  accent: data.secondaryColor,
                  button: data.tertiaryColor
                };
                rtnObj.logo = data.logoPath;
                rtnObj.extraFeatures.logoSize = data.extraFeatures?.logoSize ?? 150
            }

            return rtnObj;
        },
        true
    );
}

export async function getCompanyAdmins(company: string): Promise<any[]> {
  return await toastPromise(
    async ()=>{
      var currentQuery = query(collection(db, `/BusinessRules/${company}/Users`));
      
      let users: any[] = [];
      var currentUsers = await getDocs(currentQuery);
      
      currentUsers.forEach((doc) => {
        if (doc.exists()) {
          var userData = doc.data();

          users.push(userData);
        }
      });

      return users;
    },
    true
  );
}

export async function getCompanyData(company: string): Promise<any> {
  return await toastPromise(
    async ()=>{
      // grab system level forms.
      var c = await getDoc(doc(db, `/BusinessRules/${company}`));

      if (c && c.exists()) {
        var data = c.data();

        const co = {
          email: data.email ?? '',
          emailKey: data.emailKey ?? '',
          emailProvider: data.emailProvider ?? 'gmail',
          emailReply: data.emailReply ?? '',
          name: data.name ?? '',
          emailChecklistStyle: data.emailChecklistStyle ?? '',
          logoPath: data.logoPath ?? '',
          primaryColor: data.primaryColor ?? '',
          secondaryColor: data.secondaryColor ?? '',
          tertiaryColor: data.tertiaryColor ?? '',
          stripeCustomerId: data.stripeCustomerId ?? '',
          stripeSubscription: data.stripeSubscription ?? '',
          stripeSubscriptionStatus: data.stripeSubscriptionStatus ?? '',
          isSubscribed: data.isSubscribed ?? false,
          interpolationData: {
            CompanyName: data.interpolationData?.CompanyName ?? '',
            CompanyEmail: data.interpolationData?.CompanyEmail ?? '',
            CompanyWebsite: data.interpolationData?.CompanyWebsite ?? '',
            CompanyPortal: data.interpolationData?.CompanyPortal ?? '',
            CompanyPhone: data.interpolationData?.CompanyPhone ?? '',
            CompanyAddress: data.interpolationData?.CompanyAddress ?? ''
          },
          extraFeatures: {
            sharableStatus: data.extraFeatures?.sharableStatus ?? false,
            logoSize: data.extraFeatures?.logoSize ?? 150,
            assignAdmins: data.extraFeatures?.assignAdmins ?? false
          }
        }

        return co;
      }

      return null;
    },
    true
  );
}