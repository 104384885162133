import { toast } from 'react-toastify';

export async function toastPromise(func, silent, pendingMessage, successMessage, failedMessage) {
  if (silent) {   
    try {
      return await func();
    } catch (e) {
      console.log(e);
      toast.error(failedMessage || "An error occurred.");
    }
  } else {
    var data = await toast.promise(func, {
      pending: pendingMessage || "Processing...",
      success: successMessage || "Saved Successfully.",
      error: failedMessage || "An error occurred."
    });

    return data;
  }
}