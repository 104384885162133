import React from "react";
import Modal from "react-modal";
import { Form, Question, Option } from "../Form";
import QuestionOptionsComponent from "./QuestionOptionsComponent";

Modal.setAppElement('#root');

// TODO: Update to allow adding an icon.
export default (props: any) => {
    const [model, setModel] = React.useState<Option[]>(getInitialModel(props.value));

    function updateQuestion() {
        props.onValueChange(model);
        props.stopEditing();
    }

    function getInitialModel(value: (string|Option)[]): Option[] {
      if (!value) {
        return [];
      }

      return value.map((v: string|Option) => {
        if (typeof v == "string") {
          return new Option(v as string, "");
        } else {
          return v as Option;
        }
      });
    }

    function updateModel(value: Option, index: number) {
        var copy = [...model];

        copy[index] = value;
        
        setModel(copy);
    }

    function queryQuestions(options: any) {
        var query = options?.query;
        var parentForm = props.colDef.cellEditorParams.parentModel as Form;
        var questions: Question[] = [];
        
        parentForm.Pages.forEach(element => {
            questions.push(...element.Questions);
        });

        if (query)
            return questions.filter(q => q.Text.toLowerCase().startsWith(query.toLowerCase()));
        
        return questions;
    }

    function renderEquations() : JSX.Element[] {
        var elements: JSX.Element[] = [];

        for (let i = 0; i < model.length; i++) {
            elements.push(<QuestionOptionsComponent
                key={`RefEqComp_${i}`}
                value={model[i]}
                buttons={props.data.Type.endsWith("_BUTTONS")}
                questionResource={queryQuestions}
                setValue={(newValue: Option) => updateModel(newValue, i)}
                remove={() => removeEquation(i)}
            />);
        }

        return elements;
    }

    function removeEquation(index: number) {
        var copy = [...model];

        copy.splice(index, 1);

        setModel(copy);
    }

    function addOption() {
        var copy = [...model, new Option("", "")];

        setModel(copy);
    }

    return (
        <div>
            <Modal
                isOpen={true}
                onRequestClose={updateQuestion}
                contentLabel="Example Modal"
                portalClassName="BuilderModal Interpretations"
            >
                <div className="BuilderModalHeader">Options for Question: {props.data.Text}</div>
                <div className="BuilderModalContent">
                    <button type="button" className="btn btn-sm btn-primary" style={{ margin: 4 }} onClick={addOption}>Add</button>

                    {renderEquations()}

                    <button type="button" className="btn btn-sm btn-primary" style={{ margin: 4 }} onClick={updateQuestion}>Save</button>
                </div>
            </Modal>
        </div>
    );
}