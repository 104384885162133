import {createContext, useContext, useReducer} from 'react';
import { getAuth, signOut } from "firebase/auth";

const navOptions = {
    adminOptions: [
        {
            content: 'Admin',
            link: '/Admin',
            requiresUser: false
        },
        {
            content: "Account Settings",
            requiresUser: true,
            screen: "Settings"
        },
        {
            content: "View Submissions",
            requiresUser: true,
            screen: "Submissions"
        },
        {
            content: "Company Options",
            requiresUser: true,
            screen: "Options"
        },
        {
            content: "Form Selector",
            requiresUser: true,
            screen: "Rules"
        },
        {
            content: "Form Builder",
            requiresUser: true,
            //systemAdminOnly: true,
            screen: "Forms"
        },
        {
            content: "Edit Admins",
            requiresUser: true,
            screen: "Admins"
        },
        {
            content: "Report an Issue",
            requiresUser: true,
            screen: "ReportIssue"
        },
        {
            content: "Request a Change",
            requiresUser: true,
            screen: "RequestChange"
        },
        {
            content: "System Admin",
            systemAdminOnly: true,
            requiresUser: true,
            screen: "System"
        },
        {
            content: "Sign Out",
            click: ()=>{
              var confirmed = window.confirm("Are you sure you want to sign out?");
    
              if (confirmed) {
                signOut(getAuth());
                window.location.reload();
              }
            },
            requiresUser: true
        }
    ],
    nonAdminOptions: [],
    topButtons: []
}

const initialState = {
    currentScreen: "Submissions",
    currentSubScreens: [],
    currentSubScreen: {},
    navOptions: navOptions,
    user: null,
    companyData: {
      code: null,
      name: null
    },
    loginError: "",
    admins: []
};

export const StoreContext = createContext({
  state: initialState,
  dispatch: () => {}
});

//Action Types
export const ACTION_TYPE_AUTHENTICATION_ERROR = -1;
export const ACTION_TYPE_AUTHENTICATION = 0;
export const ACTION_TYPE_SCREEN = 1;
export const ACTION_TYPE_SUB_SCREEN = 2;
export const ACTION_TYPE_COMPANY = 3;
export const ACTION_TYPE_COMPANY_DATA = 4;
export const ACTION_TYPE_COMPANY_ADMINS_DATA = 5;

//Reducer
const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE_AUTHENTICATION_ERROR:
      return Object.assign({}, {...state, loginError: action.data});
    case ACTION_TYPE_AUTHENTICATION:
      return Object.assign({}, {...state, user: action.data});
    case ACTION_TYPE_COMPANY:
      return Object.assign({}, {...state, companyData: { ...state.companyData, code: action.data }});
    case ACTION_TYPE_COMPANY_DATA:
      return Object.assign({}, {...state, companyData: action.data });
    case ACTION_TYPE_COMPANY_ADMINS_DATA:
      return Object.assign({}, {...state, admins: action.data.admins });
    case ACTION_TYPE_SCREEN:
      if (action.data) {
        return Object.assign({}, {...state, currentScreen: action.data});
      } else {
        return state;
      }
    case ACTION_TYPE_SUB_SCREEN:
      if (action.data) {
        return Object.assign({}, {...state, currentSubScreen: action.data.current, currentSubScreens: action.data.screens});
      } else {
        return state;
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const StoreProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{state, dispatch}}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
