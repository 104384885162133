import './App.scss';
import Admin from './Admin/Admin';
import Home from './Home';
import ChecklistView from './Submission/ChecklistView';
import PaymentSuccessful from './Admin/PaymentSuccessful';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AcceptInvitation from './Admin/AcceptInvitation';
import StaticFormLink from './StaticFormLink';
import { StoreProvider } from './Store/store';
import SelfServiceCreateAccount from './Admin/SelfService/CreateAccount';
import FormTool from './Form/FormTool';
import FormResult from './Form/FormResult';
import { WelcomeIndex } from './Welcome/Index';
import { WelcomeContact } from './Welcome/Contact';

function App() {
  function render() {
    return (
      <StoreProvider>
        <Router>
          <Routes>
            <Route path="/Form/:ci/:fid/:sid" element={<FormTool />} />
            <Route path="/Form/:ci/:fid" element={<FormTool />} />

            <Route path="/FormResult/:ci/:fid/:sid" element={<FormResult />} />

            <Route path="/Admin/:id/PaymentSuccessful/:session" element={<PaymentSuccessful />} />
            <Route path="/Admin/:id" element={<Admin />} />
            <Route path="/Admin" element={<Admin />} />
            
            <Route path="/Submission/:fi/:id" element={<ChecklistView />} />
            
            <Route path="/Invitation/:ci/:iid" element={<AcceptInvitation />} />
            
            <Route path="/Link/:ci/:fl" element={<StaticFormLink />} />
            
            <Route path="/CreateAccount" element={<SelfServiceCreateAccount />} />
            
            <Route path="/Home/:area" element={<Home />} />
            
            <Route path="/" element={<WelcomeIndex />} />
            <Route path="/Contact" element={<WelcomeContact />} />
          </Routes>

          <ToastContainer position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              theme='colored'
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover />
        </Router>
      </StoreProvider>
        );
    }

    return render();
}

export default App;
