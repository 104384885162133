import React from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";
import { toastPromise } from './Helpers/ToastMessage';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    FormikEmailInput,
    FormikTextInput,
    FormikTextAreaInput
} from './FormikFields';

const functions = getFunctions();
const contactUs = httpsCallable(functions, "sendContactMeInfo");

function Home() {
  const { area } = useParams();

    const OptionsSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        description: Yup.string().required('Required')
    });

    function renderNav() {
        return (
            <div style={{ height: '70px', backgroundColor: 'var(--bs-light-blue)', position: 'fixed', top: 130, left: 0, width: '100%', zIndex: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ul className="nav nav-pills nav-fill" style={{ position: 'relative' }}>
                    <li className="nav-item">
                        <a className={area === "Description" || !area ? "nav-link active" : "nav-link"} href={area === "Description" || !area ? "#" : "/Home/Description"}>Product Description</a>
                    </li>
                    <li className="nav-item">
                        <a className={area === "How" ? "nav-link active" : "nav-link"} href={area === "How" ? "#" : "/Home/How"}>How it works</a>
                    </li>
                    <li className="nav-item">
                        <a className={area === "Why" ? "nav-link active" : "nav-link"} href={area === "Why" ? "#" : "/Home/Why"}>Why Us?</a>
                    </li>
                </ul>
            </div>
        );
    }

    function renderProductDescription() {
        if (area && area !== "Description")
            return null;

        return (
            <div className="d-flex flex-grow flex-row justify-content-center align-items-center" style={styles.homeSection}>
                <div style={styles.homeTextContainer} className="justify-content-between">
                    <div style={{ width: '100%', zIndex: 40 }}>
                        <div style={styles.homeHeader}>
                            Product Description
                        </div>
                        <div style={styles.homeSubtext}>
                            The Tax Checklist is a tool designed to streamline the process of gathering tax data for tax preparers and their clients. It has undergone extensive research, testing, and evaluation with tax professionals. By using simply worded questions, it bridges the gap between clients and tax professionals, making tax-related information more understandable. The questions are regularly updated to stay current with changing tax laws.
                        </div>
                        <div style={styles.homeSubtext}>
                            Furthermore, the Tax Checklist allows customization with your tax preparation business's branding, including logos and colors. Customized emails are sent to clients, enhancing brand visibility and trust. Each business gets a unique Tax Checklist portal for managing information and administrative needs.
                        </div>
                        <div style={styles.homeSubtext}>
                            Support is available through Q&A, instructions, and feedback opportunities. The Tax Checklist covers both personal and small business tax filings.
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function renderHowItworks() {
        if (area !== "How")
            return null;

        return (
            <div className="d-flex flex-grow flex-row justify-content-center align-items-center" style={styles.homeSection}>
                <div style={styles.homeTextContainer} className="justify-content-between">
                    <div style={{ width: '100%', zIndex: 40 }}>
                        <div style={styles.homeHeader}>
                            How it works
                        </div>
                        <div style={styles.homeSubtext}>
                            The process is simple.
                        </div>
                        <div style={styles.homeSubtext}>
                            1. Tax preparer will send their tax client's a link for completing the Tax Checklist questionnaire by text, email, social media, or their website.
                        </div>
                        <div style={styles.homeSubtext}>
                            2. Tax clients complete and submit the Tax Checklist questionnaire online using the provided link. This takes on average 15-20 minutes.
                        </div>
                        <div style={styles.homeSubtext}>
                            3. Tax Checklist then automatically sends the following...
                        </div>
                        <div style={{ ...styles.homeSubtext, paddingLeft: 10 }}>
                            <b>Tax Client</b> - An easy to follow checklist of documents the tax preparer needs to file their taxes.
                        </div>
                        <div style={{ ...styles.homeSubtext, paddingLeft: 10 }}>
                            <b>Tax Preparer</b> - When configured, a copy of the checklist of documents sent to the Tax Client, and the questionnaire answers.
                        </div>
                        <div style={styles.homeSubtext}>
                            4. Tax clients deliver the documents listed on their checklist to their preparer.
                        </div>
                        <div style={styles.homeSubtext}>
                            5. Tax preparers use the received documents and the questionnaire to complete their clients taxes.
                        </div>
                        <div style={styles.homeSubtext}>
                            Rather than taking hours, the Tax Checklist system is proven to complete this process in an average of about 15-20 minutes. Now the tax preparer and their client can spend their time more efficiently, including working on developing the strength of their business and relationships with their clients.
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderWhy() {
        if (area !== "Why")
            return null;

        return (<>
            <div className="d-flex flex-grow flex-row justify-content-center align-items-center" style={styles.homeSection}>
                <div style={styles.homeTextContainer} className="justify-content-between">
                    <div style={{ width: '50%', zIndex: 40 }}>
                        <div style={styles.homeHeader}>
                            Saving You Time With Every Client
                        </div>
                        <div style={styles.homeSubtext}>
                            We here at Tax Checklist give you the tools to interact with your customers in the smoothest and quickest ways possible while ensuring accuracy to get their taxes filed correctly. Our program allows you to simplify the information collection back and forth that so many CPA’s dread as tax season comes around. 
                        </div>
                    </div>

                    <img src="/assets/tax image.jpg" height={542} style={{ zIndex: 21 }}></img>
                </div>
            </div>
            
            <div className="d-flex flex-grow flex-row justify-content-center align-items-center" style={styles.homeSection}>
                <div style={styles.homeTextContainer} className="justify-content-between">
                    <img src="/assets/forms icon.png" height={542} style={{ zIndex: 21 }}></img>
                    <div style={{ width: '50%', zIndex: 40, textAlign: 'right' }}>
                        <div style={styles.homeHeader}>
                            Fully Automated
                        </div>
                        <div style={styles.homeSubtext}>
                            Our system allows your clients to fill out their info and then notifies them which document you’ll need to complete their taxes without any input from you. This automation will save you hours on unnecessary emails and responses. All you’ll have to do is send them the link to get started.
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-grow flex-row justify-content-center align-items-center" style={styles.homeSection}>
                <div style={styles.homeTextContainer} className="justify-content-between">
                    <div style={{ width: '50%', zIndex: 40 }}>
                        <div style={styles.homeHeader}>
                            Efficient
                        </div>
                        <div style={styles.homeSubtext}>
                            Efficiency is key when it comes to tax season. Having the ability to save time on each client will lend you the time to take on more and increase the size of your firm. In addition to all of that the best part of our program is the interactions with clients which will feel more professional as you accomplish much more each time you talk to them.
                        </div>
                    </div>

                    {/* <img src="/assets/tax icons.png" width={812} style={{ zIndex: 21 }}></img> */}
                </div>
            </div>
            
            <div className="d-flex flex-grow flex-row justify-content-center align-items-center" style={styles.homeSection}>
                <div style={styles.homeTextContainer} className="justify-content-between">
                    {/* <img src="/assets/add new user.png" height={542} style={{ zIndex: 21 }}></img> */}
                    <div></div>
                    <div style={{ width: '50%', zIndex: 40, textAlign: 'right' }}>
                        <div style={styles.homeHeader}>
                            Simple
                        </div>
                        <div style={styles.homeSubtext}>
                            Our program is very user friendly and will be very straightforward for your customers. This will ensure that they aren’t bugging you about small things, that waste your time, and keep them on track finding the correct documents each year. It will also help them feel more trust in you as there is less back and forth throughout the entire process.
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-grow flex-row justify-content-center align-items-center" style={styles.homeSection}>
                <div style={styles.homeTextContainer} className="justify-content-between">
                    <div style={{ width: '50%', zIndex: 40 }}>
                        <div style={styles.homeHeader}>
                            Customizable
                        </div>
                        <div style={styles.homeSubtext}>
                            We have full customization to make sure your brand is represented but also so that you will be able to ask the questions you find most important for your clientele. This will allow you to continue building your business the way you prefer while also improving your speed and accuracy.
                        </div>
                    </div>

                    {/* <img src="/assets/tax image.jpg" height={542} style={{ zIndex: 21 }}></img> */}
                </div>
            </div>
            
            <div className="d-flex flex-grow flex-row justify-content-center align-items-center" style={styles.homeSection}>
                <div style={styles.homeTextContainer} className="justify-content-between">
                    <img src="/assets/add new user.png" height={542} style={{ zIndex: 21 }}></img>
                    <div style={{ width: '50%', zIndex: 40, textAlign: 'right' }}>
                        <div style={styles.homeHeader}>
                            Onboarding, made easy
                        </div>
                        <div style={styles.homeSubtext}>
                            We are here to help you! This means our team will help you every step of the way to have you ready and comfortable with the system before tax season is in full swing. 
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }

    return (
        <div className='d-flex flex-column h-100'>
            <div className="d-flex flex-row justify-content-between pt-5" style={{ padding: '30px 140px', zIndex: 30, background: 'white', position: 'fixed', top: 0, left: 0, width: '100%', }} >
                <img src="/assets/logo-1.png" width={354} height={54}></img>
                <div>
                    <button type='button' className="btn btn-primary" style={{ width: '260px', height: '50px', marginRight: 25 }} onClick={ e => window.location = "/Admin" }>Login</button>
                    <button type='button' className="btn btn-primary" style={{ width: '260px', height: '50px' }} onClick={ e => window.location = "/CreateAccount" }>Register</button>
                </div>
            </div>
            { renderNav() }
            <div style={styles.blueCircle}></div>
            <div style={styles.greenCircle}></div>
            <div className="d-flex flex-column" style={{ flex: '1 0 auto', paddingTop: 200 }}>

                { renderProductDescription() }
                { renderHowItworks() }
                { renderWhy() }                

                <div className="d-flex flex-grow flex-row justify-content-center align-items-center" style={styles.homeSection}>
                    <div style={styles.homeTextContainer} className="justify-content-between">
                        <div style={{ width: '50%', zIndex: 40 }}>
                            <div style={styles.homeHeader}>
                                Contact Us
                            </div>
                            <div style={styles.homeSubtext}>
                                Reach out today to schedule your free demo and see how much time we can save you.
                            </div>
                            
                            <Formik
                                initialValues={{
                                    name: '',
                                    description: '',
                                    email: ''
                                }}
                                validationSchema={OptionsSchema}
                                onSubmit={values => {
                                    // same shape as initial values
                                    toastPromise(async () => {
                                        await contactUs(values);
                                    }, false, "Loading...", "Success!", "An error occurred.");
                                }}
                            >
                                {({ errors, touched }) => (
                                    <div>
                                        <Form>
                                            <Field errors={errors} name="name" component={FormikTextInput} formiklabel="Name" />
                                            <Field errors={errors} name="email" component={FormikEmailInput} formiklabel="Email" />
                                            <Field errors={errors} name="description" component={FormikTextAreaInput} formikrows={5} formiklabel="Notes" />
                                            <button type="submit" className="btn btn-secondary" style={{ width: '256px', height: '50px' }}>Request a demo</button>
                                        </Form>
                                    </div>
                                )}
                            </Formik>
                        </div>

                        <img src="/assets/tax icons.png" width={812} style={{ zIndex: 21 }}></img>
                    </div>
                </div>
                
                {/* <div className="d-flex flex-grow flex-row justify-content-center align-items-center" style={styles.homeSection}>
                    <div style={styles.homeTextContainer} className="justify-content-between">
                        <img src="/assets/add new user.png" height={542} style={{ zIndex: 21 }}></img>
                        <div style={{ width: '50%', zIndex: 40, textAlign: 'right' }}>
                            <div style={styles.homeHeader}>
                                FAQ
                            </div>
                            <div style={styles.homeSubtext}>
                                We provide the necessary linking tools to let your clients know what they need
                                to provide you for their taxes saving you valuable time and resources during tax
                                season.
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            
            <footer>
                <div style={styles.homeFooter}>
                    <div style={styles.footerContent}>
                        <div>
                            Request a Demo today!
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

const styles = {
    homeTextContainer: {
        display: 'flex',
        width: '90%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    homeHeader: {
        fontSize: '60px',
        color: 'var(--bs-primary)',
        lineHeight: '70px',
        fontWeight: 'bold',
        fontFamily: 'Open Sans, Extrabold',
    },
    homeSubtext: {
        fontStyle: 'Open Sans, Light',
        fontSize: '16px',
        color: 'var(--bs-secondary)',
        lineHeight: '26px',
        paddingTop: 10
    },
    homeFooter: {
        height: '156px',
        backgroundColor: 'var(--bs-primary)',
        display: 'flex',
        fontFamily: 'Open Sans, Bold',
        fontSize: '26px',
        color: 'white',
        lineHeight: '36px',
        justifyContent: 'center',
        gap: '10px',
        alignItems: 'center',
        zIndex: '30',
        position: 'sticky'
    },
    homeSection: {
        padding: '20px',
        marginTop: '30px',
        marginBottom: '30px',
        zIndex: 21,
    },
    footerContent: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center'
    },
    blueCircle: {
        height: '1452px',
        backgroundColor: '#088BF7',
        zIndex: '20',
        position: 'fixed',
        left: '1073px',
        top: '-1081px',
        width: '1452px',
        borderRadius: '1226px 1226px 1226px 1226px',
        opacity: '0.33',
    },
    greenCircle: {
        position: 'fixed',
        left: '-782px',
        height: '1452px',
        width: '1452px',
        borderRadius: '1226px 1226px 1226px 1226px',
        backgroundColor: '#E6F5F1',
        zIndex: '20',
        top: '342px',
    }
};

export default Home;