import React from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const contactUs = httpsCallable(functions, "sendContactMeInfo");

function PaymentSuccessful() {
  const { id, session } = useParams();

    return (
        <div className='d-flex flex-column h-100'>
            <div className="d-flex flex-row justify-content-between pt-5" style={{ padding: '30px 140px', zIndex: 30, background: 'white', position: 'fixed', top: 0, left: 0, width: '100%', }} >
                <img src="/assets/logo-1.png" width={354} height={54}></img>
                <div>
                    <button type='button' className="btn btn-primary" style={{ width: '260px', height: '50px', marginRight: 25 }} onClick={ e => window.location = "/Admin/" + id }>Login</button>
                </div>
            </div>

            <div style={{ height: '70px', color: '#045aa1', fontSize: 'larger', backgroundColor: 'var(--bs-light-blue)', position: 'fixed', top: 130, left: 0, width: '100%', zIndex: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <b>Success! Thank you for your payment!</b>
            </div>

            <div style={styles.blueCircle}></div>
            <div style={styles.greenCircle}></div>

            <div className="d-flex flex-column" style={{ flex: '1 0 auto', paddingTop: 200 }}>
                <div className="d-flex flex-grow flex-row justify-content-center align-items-center" style={styles.homeSection}>
                    <div style={styles.homeTextContainer} className="justify-content-between">
                        <div style={{ width: '100%', zIndex: 40 }}>
                            <div style={styles.homeHeader}>
                                Next Steps
                            </div>
                            <div style={styles.homeSubtext}>
                                You are well on your way to finalizing your set up - just a few more steps to go!
                            </div>
                            <div style={styles.homeSubtext}>
                                1. Set up your outgoing email
                            </div>
                            <div style={styles.homeSubtext}>
                                2. Activate and configure your checklist email
                            </div>
                            <div style={styles.homeSubtext}>
                                3. Send your links to your clients!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles = {
    homeTextContainer: {
        display: 'flex',
        width: '90%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    homeHeader: {
        fontSize: '60px',
        color: 'var(--bs-primary)',
        lineHeight: '70px',
        fontWeight: 'bold',
        fontFamily: 'Open Sans, Extrabold',
    },
    homeSubtext: {
        fontStyle: 'Open Sans, Light',
        fontSize: '16px',
        color: 'var(--bs-secondary)',
        lineHeight: '26px',
        paddingTop: 10
    },
    homeFooter: {
        height: '156px',
        backgroundColor: 'var(--bs-primary)',
        display: 'flex',
        fontFamily: 'Open Sans, Bold',
        fontSize: '26px',
        color: 'white',
        lineHeight: '36px',
        justifyContent: 'center',
        gap: '10px',
        alignItems: 'center',
        zIndex: '30',
        position: 'sticky'
    },
    homeSection: {
        padding: '20px',
        marginTop: '30px',
        marginBottom: '30px',
        zIndex: 21,
    },
    footerContent: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center'
    },
    blueCircle: {
        height: '1452px',
        backgroundColor: '#088BF7',
        zIndex: '20',
        position: 'fixed',
        left: '1073px',
        top: '-1081px',
        width: '1452px',
        borderRadius: '1226px 1226px 1226px 1226px',
        opacity: '0.33',
    },
    greenCircle: {
        position: 'fixed',
        left: '-782px',
        height: '1452px',
        width: '1452px',
        borderRadius: '1226px 1226px 1226px 1226px',
        backgroundColor: '#E6F5F1',
        zIndex: '20',
        top: '342px',
    }
};

export default PaymentSuccessful;