import { useEditor, EditorContent, BubbleMenu, ReactRenderer } from '@tiptap/react';
import Mention from '@tiptap/extension-mention';
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit';
import InterpolationMentionList from './InterpolationMentionList';
import tippy from 'tippy.js';

const TiptapCompanyReference = (props) => {
  const editor = useEditor({
    extensions: [
        Document,
        Paragraph,
        Text,
        Underline,
        StarterKit,
        Mention.configure({
            HTMLAttributes: {
                class: 'company_reference', //do not change without full understanding of our processor
            },
            renderText({ options, node }) {
                return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
            },
            renderHTML({ options, node }) {
                return [
                    "span",
                    {
                        "data-id": node.attrs.id,
                        "data-label": node.attrs.label,
                        "data-type": "mention",
                        class: "company_reference"
                    },
                    `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
                ];
            },
            suggestion: {
                char: '@',
                decorationClass: 'interpretation_item',
                items: props.itemResource,
                render: renderInterpolationMention
            }
        }),
    ],
    content: props.value,
    onUpdate: updateParent,
  });

  function updateParent ({ editor }) {
    props.setValue(editor.getHTML());
  }

  function renderInterpolationMention () {
    let component
    let popup

    return {
        onStart: props => {
            component = new ReactRenderer(InterpolationMentionList, {
                props,
                editor: props.editor,
            })

            if (!props.clientRect) {
                return
            }

            popup = tippy('body', {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
            })
        },

        onUpdate(props) {
            component.updateProps(props)

            if (!props.clientRect) {
                return
            }

            popup[0].setProps({
                getReferenceClientRect: props.clientRect,
            })
        },

        onKeyDown(props) {
            if (props.event.key === 'Escape') {
                popup[0].hide()

                return true
            }

            return component.ref?.onKeyDown(props)
        },

        onExit() {
            popup[0].destroy()
            component.destroy()
        },
    }
  }

  if (!editor) {
    return null
  }

  return (<>        
        <div className='TipTapEditor'>
            <EditorContent editor={editor} />
            {editor && <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
              <div className='btn-group' style={{ borderWidth: 2, borderStyle: 'solid', borderColor: 'black' }}>
                <button
                  onClick={() => editor.chain().focus().toggleBold().run()}
                  className={`${editor.isActive('bold') ? 'is-active' : ''} btn btn-sm btn-primary`}
                >
                  <b>bold</b>
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleItalic().run()}
                  className={`${editor.isActive('italic') ? 'is-active' : ''} btn btn-sm btn-primary`}
                >
                  <i>italic</i>
                </button>
                <button
                  onClick={() => editor.chain().focus().toggleUnderline().run()}
                  className={`${editor.isActive('strike') ? 'is-active' : ''} btn btn-sm btn-primary`}
                >
                  <u>underline</u>
                </button>
              </div>
            </BubbleMenu>}
        </div>
    </>)
}

export default TiptapCompanyReference