import React from 'react';
import { initializeApp } from '@firebase/app';
import { getFirestore, getDocs, query, collection, where, writeBatch, doc, getDoc } from '@firebase/firestore';
import { toastPromise } from '../Helpers/ToastMessage';
import SystemCompanyOptions from './SystemCompanyOptions';
import { getFunctions, httpsCallable } from "firebase/functions";
import RuleForm from './RuleForm';
import { useCompany, useScreens } from '../Store/storeFunctions';
import { BeatLoader } from 'react-spinners';
import SystemSubmissions from './SystemSubmissions';

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const db = getFirestore(app);
const functions = getFunctions();
const sendCompanyInviteEmail = httpsCallable(functions, "sendCompanyInviteEmail");

function SystemAdmin(props) {
  const [companies, setCompanies] = React.useState();
  const [createCompany, setCreateCompany] = React.useState();
  const [tempEmail, setTempEmail] = React.useState();

  const company = useCompany();
  const screens = useScreens();

  React.useEffect(() => {
    if (company.companyData?.code && company.companyData?.code == 'tcpSystemAdministration' && !companies) {
      getCompanies();
    }
  }, []);

  function getCompanies() {
    toastPromise(async () => {
      var actives = [];
      var c = await getDocs(query(collection(db, '/Companies'), where('active', '==', true)));

      c.forEach((doc) => {
        if (doc.exists()) {
          actives.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });
      
      setCompanies(actives);
    }, true);
  }

  function createNewCompany() {
    setCreateCompany(true);
    screens.clearSubScreens();
  }

  function sendInvitation(c, email) {
    toastPromise(async () => {
      var cmpny = await getDoc(doc(db, `/BusinessRules/${c.id}`));

      if (cmpny && cmpny.exists()) {
        const options = cmpny.data();

        sendCompanyInviteEmail({
          company: options,
          emailAddresses: email,
          id: c.id
        });
      }
    });
  }

  function inactivateCompany(id) {
    toastPromise(async () => {
      var batch = writeBatch(db);

      batch.update(doc(db, `/BusinessRules/${id}`), {
        active: false
      });
      
      batch.update(doc(db, `/Companies/${id}`), {
        active: false
      });

      await batch.commit();

      var actives = [...companies];

      actives.splice(actives.indexOf(o => o.id == id), 1);

      setCompanies(actives);
    });
  }

  function completedSystemCompanyEdit(){
    setCreateCompany(false);
    screens.clearSubScreens();
    getCompanies();
  }

  function viewSubmissions(id) {
    screens.setSubScreen({
      content: id,
      data: id,
      type: "CompanySubmissions"
    });
  }

  function editOptions(id) {
    screens.setSubScreen({
      content: id,
      data: id,
      type: "EditOptions"
    });
  }

  function editForm(id) {
    screens.setSubScreen({
      content: id,
      data: id,
      type: "EditForms"
    });
  }

  function renderCompanies() {
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            companies.map(c => {
              return (
                <tr>
                  <td style={{ textAlign: 'left' }}>{c.id}</td>

                  <td>
                    <input className="form-control form-control-sm"
                      disabled={true}
                      value={c.active ? 'Active' : 'Inactive'} />
                  </td>

                  <td>
                    <div className="btn-group">
                      <button type="button" className="btn btn-sm btn-primary dropdown-toggle" data-bs-toggle="dropdown">Select Action</button>
                      
                      <ul className="dropdown-menu" role="menu">
                        <li><a className="dropdown-item" onClick={()=>editOptions(c.id)}>Edit Options</a></li>
                        <li><a className="dropdown-item" onClick={()=>editForm(c.id)}>Form Editor</a></li>
                        <li><a className="dropdown-item" onClick={()=>viewSubmissions(c.id)}>View Submissions</a></li>
                        <li><a className="dropdown-item" onClick={()=>inactivateCompany(c.id)}>Inactivate</a></li>
                        <li><a className="dropdown-item" onClick={()=>sendInvitation(c, tempEmail)} title={`Send invite to ${tempEmail}`}>Invite</a></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    );
  }

  function viewCompanySubmissions() {
    if (screens.state.currentSubScreens.length > 0 && screens.state.currentSubScreens[0].type == "CompanySubmissions") {
      return screens.state.currentSubScreens[0].data;
    } else {
      return undefined;
    }
  }

  function editCompany() {
    if (screens.state.currentSubScreens.length > 0 && screens.state.currentSubScreens[0].type == "EditOptions") {
      return screens.state.currentSubScreens[0].data;
    } else {
      return undefined;
    }
  }

  function editFormCompany() {
    if (screens.state.currentSubScreens.length > 0 && screens.state.currentSubScreens[0].type == "EditForms") {
      return screens.state.currentSubScreens[0].data;
    } else {
      return undefined;
    }
  }

  function render() {
    if (createCompany || editCompany()) {
      return <SystemCompanyOptions createCompany={createCompany}
        companies={companies}
        company={editCompany()}
        back={()=> {
          setCreateCompany(false);
          screens.clearSubScreens();
        }}
        finished={completedSystemCompanyEdit} />
    } else if (editFormCompany()) {
      return <RuleForm company={editFormCompany()} isSystem={true} editOptions={props.editOptions} editAdmins={props.editAdmins} viewSubmissions={props.viewSubmissions} />
    } else if (viewCompanySubmissions()) {
      return <SystemSubmissions company={viewCompanySubmissions()} />
    }

    if (!companies) {
      return (<div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div>);
    }

    return (
      <div className="center">
        <input type="button" className="btn btn-sm btn-success" value="Create New Company" onClick={createNewCompany} />

        <div className="container">
          <div className="form-group">
            <label className="control-label" htmlFor="email">Invitation Email:</label>
            <input type="email" className="form-control form-control-sm" id="email" value={tempEmail} onChange={e => setTempEmail(e.target.value)} placeholder="Enter email"/>
          </div>

          <div className="container-fluid center">
            <div className="form-horizontal">
              {renderCompanies()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return render();
}

export default SystemAdmin;