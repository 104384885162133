import React from "react";
import { ReferenceEquation, Comparisons } from "../Form";

interface ReferenceEquationElement {
    value: ReferenceEquation,
    questionResource: (options?: any) => any[],
    setValue: (newValue: ReferenceEquation) => void,
    remove: () => void
}

export default (props: ReferenceEquationElement) => {
    function updateModel(field: "QuestionID"|"Comparison"|"Value"|"Group", value: string | Comparisons) {
        var copy = new ReferenceEquation(props.value.QuestionID, props.value.Value, props.value.Comparison, props.value.OrGroup);

        // update the field according to params
        if (field == "Comparison") {
            copy.Comparison = value as Comparisons;
        } else if (field == "Group") {
            var parsed = Number.parseInt(value);
            copy.OrGroup = Number.isNaN(parsed) ? undefined : parsed;
        } else {
            copy[field] = value;
        }

        props.setValue(copy);
    }

    function getComparisonOptions() : JSX.Element[] {
        var keys = Object.keys(Comparisons) as Array<string>

        return keys.map((key: string, index: number, array: string[]) => {
            var thinnedValue = key.replaceAll('_', ' ');

            return <option key={index} value={key}>{thinnedValue}</option>
        });
    }

    function getQuestionOptions() : JSX.Element[] {
        var questions = props.questionResource();

        return questions.map((question: any, index: number) => {
            return <option key={index} value={question.ID}>{question.Text}</option>
        });
    }

    function getQuestionSelect() : JSX.Element {
        if (
            props.value.Comparison == Comparisons.ALWAYS || 
            props.value.Comparison == Comparisons.NEVER
        ) {
            return <></>;
        }

        return (
            <select value={props.value.QuestionID} onChange={v => updateModel("QuestionID", v.target.value)} style={{ maxWidth: 300 }}>
              <option key={-1} value={""}>N/A</option>
              { getQuestionOptions() }
            </select>
        );
    }

    function getOrGroup() : JSX.Element {
      return <input type="number" value={props.value.OrGroup ?? 0} onChange={v => updateModel("Group", v.target.value)} min={0} max={100}></input>;
    }

    function getValueInput() {
        switch (props.value.Comparison) {
            case Comparisons.CONTAINS:
            case Comparisons.ENDS_WITH:
            case Comparisons.EQUAL_TO:
            case Comparisons.EXCLUDES:
            case Comparisons.NOT_EQUAL_TO:
            case Comparisons.STARTS_WITH:
                return <input type="text" value={props.value.Value} onChange={v => updateModel("Value", v.target.value)}></input>;
            default:
                return null;
        }
    }

    return (
        <div className="ReferenceEquation">
            <button className="bi-dash-circle danger btn btn-sm btn-danger" onClick={props.remove}></button>

            { getQuestionSelect() }

            <select value={props.value.Comparison} onChange={v => updateModel("Comparison", v.target.value)}>
                { getComparisonOptions() }
            </select>

            { getValueInput() }

            { getOrGroup() }
        </div>
    );
}