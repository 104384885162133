import React from 'react';
import { initializeApp } from '@firebase/app';
import { getFirestore, doc, getDocs, query, collection, serverTimestamp, writeBatch, getDoc } from '@firebase/firestore';
import { toastPromise } from '../Helpers/ToastMessage';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCompany } from '../Store/storeFunctions';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { FormikInlineEmailInput } from '../FormikFields';
import { BeatLoader } from 'react-spinners';
import Modal from "react-modal";

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const db = getFirestore(app);
const functions = getFunctions();
const sendCompanyInviteEmail = httpsCallable(functions, "sendCompanyInviteEmail");

function EditAdmins(props) {
  const [admins, setAdmins] = React.useState([]);
  const [requestedAdmins, setRequestedAdmins] = React.useState([]);
  const [adminInvites, setAdminInvites] = React.useState({
    emails: [],
  });
  const [addUsers, setAddUsers] = React.useState();

  const InvitesSchema = Yup.object().shape({
    emails: Yup.array().of(Yup.string().email('Invalid invitation emails'))
  });

  const company = useCompany();

  React.useEffect(() => {
    if (company.companyData?.code) {
      getCompanyAdmins();
    }
  }, []);

  function getCompanyAdmins() {
    toastPromise(async ()=>{
      var currentQuery = query(collection(db, `/BusinessRules/${company.companyData?.code}/Users`));
      var requestsQuery = query(collection(db, `/BusinessRules/${company.companyData?.code}/Requests`));
      
      let users = [];
      var currentUsers = await getDocs(currentQuery);
      
      currentUsers.forEach((doc) => {
        if (doc.exists()) {
          users.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });
      
      let requests = []
      var requestedUsers = await getDocs(requestsQuery);
      
      requestedUsers.forEach((doc) => {
        if (doc.exists()) {
          requests.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });
      
      setAdmins(users);
      setRequestedAdmins(requests);
    }, true);
  }

  async function sendInvites(invites) {
    toastPromise(async () => {
      if (invites.emails) {
        var addresses = invites.emails.join(";");
        
        sendCompanyInviteEmail({
          company: company.companyData,
          emailAddresses: addresses,
          id: company.companyData?.code
        });
        setAdminInvites({ emails: [] });
      }
    });
  }

  async function acceptRequest(user) {
    toastPromise(async () => {
      var batch = writeBatch(db);
      
      batch.set(doc(db, `/BusinessRules/${company.companyData?.code}/Users/${user.id}`), {
        Name: user.Name,
        Email: user.Email,
        Timestamp: serverTimestamp()
      });
      
      batch.delete(doc(db, `/BusinessRules/${company.companyData?.code}/Requests/${user.id}`));
      
      await batch.commit();

      getCompanyAdmins();
    });
  }

  async function deleteUser(user) {
    var confirmed = window.confirm("Are you sure you want to delete " + user.Name + "?");

    if (confirmed) {
      toastPromise(async ()=>{
        var batch = writeBatch(db);
        
        batch.delete(doc(db, `/BusinessRules/${company.companyData?.code}/Users/${user.id}`));
        
        await batch.commit();
        
        getCompanyAdmins();
      });
    }
  }

  async function denyRequest(user) {
    toastPromise(async ()=>{
      var batch = writeBatch(db);
      
      batch.delete(doc(db, `/BusinessRules/${company.companyData?.code}/Requests/${user.id}`));
      
      await batch.commit();

      getCompanyAdmins();
    });
  }

  function renderUsers() {
    return <div style={styles.groupOuter}>
      <div style={styles.groupInner}>
        { admins.map(v => renderUser(v)) }
      </div>
    </div>
  }

  function renderUser(user) {
    return (
      <div className="row" style={styles.users}>
        <div className="col-sm-1"></div>
        <div className="col-sm-8">
          <div><b>{user.Name}</b></div>
          <div><small>{user.Email}</small></div>
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-2">
          <span className="btn btn-sm btn-danger" style={{ display: 'inline-block', marginLeft: 5 }} onClick={() => deleteUser(user)}>
            <span className="bi-trash"></span>
          </span>
        </div>
      </div>
    );
  }

  function renderRequests() {
    if (requestedAdmins.length === 0) {
      return <div style={styles.groupOuter}>
        <div style={styles.groupInner}>
          <div className="row" style={styles.users}>
            <div className="col-sm-1"></div>
            <div className="col-sm-8">
              <div><b>None</b></div>
              <div><small>None</small></div>
            </div>
            <div className="col-sm-1"></div>
            <div className="col-sm-2">
            </div>
          </div>
        </div>
      </div>
    }
    
    return <div style={styles.groupOuter}>
      <div style={styles.groupInner}>
        { requestedAdmins.map(v => renderRequest(v)) }
      </div>
    </div>
  }

  function renderRequest(user) {
    return (
      <div className="row" style={styles.users}>
        <div className="col-sm-1"></div>
        <div className="col-sm-7">
          <div><b>{user.Name}</b></div>
          <div><small>{user.Email}</small></div>
        </div>
        <div className="col-sm-4">
          <span className="btn btn-sm btn-success" style={{ display: 'inline-block', marginLeft: 5 }} onClick={() => acceptRequest(user)}>
            <span className="bi-check-circle"></span>
          </span>
          <span className="btn btn-sm btn-danger" style={{ display: 'inline-block', marginLeft: 5 }} onClick={() => denyRequest(user)}>
            <span className="bi-trash"></span>
          </span>
        </div>
      </div>
    );
  }

  function render() {
    if (!admins && !requestedAdmins) {
      return (<div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div>);
    }

    return (
      <div className="container" style={styles.main}>
        <div className="row">
          <div className="col-sm-5" style={styles.section}>
            <h5 style={{ color: '#045AA1', marginBottom: 16 }}>Admin Requests</h5>
            { renderRequests() }
          </div>
          
          <div className="col-sm-7" style={styles.section}>
            <div className="row" style={{ marginBottom: 8 }}>
              <div className="col-sm-10">
                <h5 style={{ color: '#045AA1' }}>Current Admins</h5>
              </div>
              <div className="col-sm-2">
                <span className="btn btn-sm btn-success" style={{ display: 'inline-block', float: 'right' }} onClick={() => setAddUsers(true)}>
                  <span className="bi-plus-lg"></span>
                </span>
              </div>
            </div>
            { renderUsers() }
          </div>
        </div>

        { !addUsers ? null : 
          <Modal
              isOpen={true}
              onRequestClose={() => setAddUsers(false)}
              contentLabel="Example Modal"
              portalClassName="BuilderModal Required"
          >
              <div className="BuilderModalHeader">Email Invitations:</div>
              <div className="BuilderModalContent">
                <Formik
                  initialValues={adminInvites}
                  validationSchema={InvitesSchema}
                  onSubmit={values => {
                    // same shape as initial values
                    setAdminInvites(values);
                    sendInvites(values);
                  }}
                >
                  {({ values, errors, touched }) => (
                    <Form>
                      <FieldArray name="emails">
                        {({ insert, remove, push }) => (
                          <div style={{ position: 'relative', marginBottom: 50 }}>
                          {
                          values.emails.map((email, index) => (
                              <div className="row" key={index}>
                                <div className="col">
                                  <Field
                                    name={`emails.${index}`}
                                    component={FormikInlineEmailInput}
                                    formiklabel="Email Invite"
                                  />
                                  <span className="btn btn-sm btn-danger" style={{ display: 'inline-block', marginLeft: 5 }} onClick={() => remove(index)}>
                                    <span className="bi-trash"></span>
                                  </span>
                                </div>
                              </div>
                            ))}

                          {errors.emailInvitations?.length && touched.emailInvitations ? (<div style={{color: 'var(--red)'}}>{errors.emailInvitations.at(0)}</div>) : null}
                          
                          <div className="mb-3 center"> 
                            <div className="btn btn-sm btn-primary" style={{ marginRight: 8 }} onClick={() => push('')}><i className='bi-plus'/> Email Invitation</div>
                            <button className="btn btn-sm btn-primary" type="submit">Send</button>
                          </div>
                        </div>
                        )}
                      </FieldArray>
                    </Form>
                  )}
                </Formik>
              </div>
          </Modal>
        }
      </div>
    );
  }

  return render();
}

const styles = {
  groupOuter: {
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 10,
    borderStyle: 'solid',
    height: '60vh',
    padding: 8
  },
  groupInner: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    width: '100%'
  },
  main: {
    paddingTop: 30,
    margin: 'auto',
    width: '75%'
  },
  users: {
    height: 60,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid'
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    position: 'absolute',
    top: 0,
    left: 0,
    alignItems: "center",
    height: '100%',
    width: '100%',
    backgroundColor: '#A7A7A75D'
  },
  card: {
    width: '60%'
  }
}

export default EditAdmins;