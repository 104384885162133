import React from 'react';
import { initializeApp } from '@firebase/app';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserSessionPersistence, signOut } from "firebase/auth";
import { getFirestore, doc, setDoc, serverTimestamp } from '@firebase/firestore';
import { useCompany } from '../Store/storeFunctions';

const auth = getAuth();

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const db = getFirestore(app);

function CreateAccount(props) {
  const [email, setEmail] = React.useState();
  const [fullName, setFullName] = React.useState();
  const [password, setPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();
  const [validationMessage, setValidationMessage] = React.useState();
  const [requested, setRequested] = React.useState();
  const [showPassword, setShowPassword] = React.useState('password');
  const [showConfirmPassword, setShowConfirmPassword] = React.useState('password');
  const company = useCompany();

  async function createAccount() {
    if (fullName && email && password && password === confirmPassword) {
      await setPersistence(auth, browserSessionPersistence)
        .then(() =>{
          return createUserWithEmailAndPassword(auth, email, password)
        })
        .then(async (cred) => {
          await setDoc(doc(db, `/BusinessRules/${company.companyData?.code}/Requests/${cred.user.uid}`), {
            Name: fullName,
            Email: cred.user.email,
            Timestamp: serverTimestamp()
          }).then(() => {
            signOut(getAuth());
          });
        })
        .then(() => {
          setRequested(true);
        });
    } else {
      if (!email) {
        setValidationMessage("Email is required.");
      } else if (!password) {
        setValidationMessage("Password is required and must match the confirmed password.");
      } else if (!fullName) {
        setValidationMessage("Name is required.");
      } else {
        setValidationMessage("Passwords do not match.");
      }
    }
  }

  function renderForm() {
    return (
      <div className="form-horizontal" style={{width: '50%',margin: 'auto'}}>
        <div className="form-group">
          <label className="control-label col-sm-2" htmlFor="name">Name:</label>
          <div className="col-sm-10">
            <input type="text" className="form-control form-control-sm" id="name" value={fullName} onChange={e => setFullName(e.target.value)} placeholder="Enter your full name"/>
          </div>
        </div>
        
        <div className="form-group">
          <label className="control-label col-sm-2" htmlFor="email">Email:</label>
          <div className="col-sm-10">
            <input type="email" className="form-control form-control-sm" id="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter email"/>
          </div>
        </div>
        
        <div className="form-group">
          <label className="control-label col-sm-2" htmlFor="pwd">Password:</label>
          <div className="col-sm-10">
            <div className="input-group mb-3" style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : {}}>
              <input type={showPassword} className="form-control form-control-sm" id="pwd" value={password} onChange={e => setPassword(e.target.value)} placeholder="Enter a password" />
              
              <button className="btn btn-sm btn-primary reveal" type="button" onClick={() => {
                  if (showPassword === "password") {
                      setShowPassword("text");
                  } else {
                      setShowPassword("password");
                  }
              }}>{showPassword === "password" ? <i className="bi-eye"></i> : <i className="bi-eye-slash"></i>}</button>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-sm-2" htmlFor="cfrmpwd">Confirm Password:</label>
          <div className="col-sm-10"> 
            <div className="input-group mb-3">
              <input type={showConfirmPassword} className="form-control form-control-sm" style={{ width: '100%' }} id="cfrmpwd" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} placeholder="Re-enter password"/>
              
              <button className="btn btn-sm btn-primary reveal" type="button" onClick={() => {
                  if (showConfirmPassword === "password") {
                      setShowConfirmPassword("text");
                  } else {
                      setShowConfirmPassword("password");
                  }
              }}>{showConfirmPassword === "password" ? <i className="bi-eye"></i> : <i className="bi-eye-slash"></i>}</button>
            </div>
          </div>
        </div>

        <div className="form-group"> 
          <div className="col-sm-12">
            <button className="btn btn-sm btn-primary" onClick={createAccount}>Register</button>
            <button className="btn btn-sm btn-danger" onClick={props.cancel}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }

  function render() {
    return (
      <div className="jumbotron center">
        <h1>Admin Registration</h1>
        <p>Enter your email address and a password to request admin access to {company.companyData?.code}</p>
        { validationMessage ? <p style={{ color: 'var(--red)' }}>{validationMessage}</p> : null}
        { requested ? <p>Access to {company.companyData?.code} has been requested.</p> : renderForm()}
      </div>
    );
  }

  return render();
}

export default CreateAccount;