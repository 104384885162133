import React from "react";
import { AgGridReact } from 'ag-grid-react';
import Modal from "react-modal";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import {
    VisibilityCellRenderer,
    RequiredCellRenderer,
    FullWidthCellRenderer,
    ValidationCellRenderer,
    InterpretationsCellRenderer,
    QuestionOptionsCellRenderer,
    QuestionTextCellRenderer,
    HelpTextCellRenderer
} from "../Helpers/AgGridCellRenderers";
import Visibility from "./BuilderTools/Visibility";
import Required from "./BuilderTools/Required";
import Validations from "./BuilderTools/Validations";
import Interpretations from "./BuilderTools/Interpretations";
import Bubble from "../Helpers/Bubble";

import * as FormikTools from 'formik';
import * as Yup from 'yup';
import {
    FormikSelect,
    FormikCheckBoxSingle,
    FormikTextInput,
} from '../FormikFields';

import * as FormData from './BuilderTools/FormBuilderData';
import { Form, Question } from "./Form";
import TiptapCompanyReference from "./BuilderTools/TipTapCompanyReference";
import QuestionOptions from "./BuilderTools/QuestionOptions";
import QuestionEditor from "./BuilderTools/QuestionEditor";
import HelpTextEditor from "./BuilderTools/HelpTextEditor";
import { toastPromise } from "../Helpers/ToastMessage";

export interface FormBuilderProps {
    company: string,
    createNew: boolean,
    FormID: string
}

function FormBuilder(props: FormBuilderProps) {
  // Page index
  const gridRef = React.useRef<any>();

  /**
   * formModel will represent the Form class instance.
   * another function will extract the rows for the grid.
   */
  const [formModel, setFormModel] = React.useState<Form | undefined>(undefined);
  const [tableModel, setTableModel] = React.useState<any[] | undefined>(undefined);
  const [questionToDelete, setQuestionToDelete] = React.useState<Question | undefined>(undefined);
  const [editPageIndex, setEditPageIndex] = React.useState<number | undefined>(undefined);
  const [editPageSummaryText, setEditPageSummaryText] = React.useState<string | undefined>(undefined);
  const [showOutput, setShowOutput] = React.useState<boolean>(false);

  const FormOptionsSchema = Yup.object().shape({
    ID: Yup.string().required('Required'),
    Name: Yup.string().required('Required'),
    Type: Yup.string().required('Required'),
    Published: Yup.boolean(),
    PublishedDate: Yup.date().nullable(),
    Active: Yup.boolean(),
    Questions: Yup.array(),
    Interpretations: Yup.array(),
  });

  const types = [
    { value: "INDIVIDUAL", text: "Individual" },
    { value: "BUSINESS", text: "Business" },
    { value: "ONBOARDING_INTAKE", text: "Onboarding Intake" },
    { value: "PAYROLL_REVIEW", text: "Annual Payroll Review" }
  ];

  /**
   * Grid Column Defs
   */
  const columnDefs = [
    {
        //checkboxSelection: true,
        valueGetter: (params: any) => params.data.Question?.ID,
        resizable: false,
        width: 50,
        rowDrag: true
    },
    {
        headerName: 'Question',
        field: 'Text',
        resizable: true,
        sortable: true,
        filter: true,
        editable: true,
        autoHeight: true,
        wrapText: true,
        width: 350,
        cellRenderer: QuestionTextCellRenderer,
        cellEditor: QuestionEditor,
        cellEditorParams: {
          parentModel: formModel
        },
        cellEditorPopup: true,
        cellEditorPopupPosition: 'over',
        singleClickEdit: true
    },
    {
        headerName: 'Type',
        field: 'Type',
        resizable: true,
        sortable: true,
        filter: true,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: ["NORMAL","FREE","SINGLE","SINGLE_BUTTONS","MULTI","MULTI_BUTTONS","YESNO","CHECK","ADDRESS"],
        },
        width: 100
    },
    {
        headerName: 'Options',
        field: 'Options',
        editable: (params: any) => {
            return params.data.Type == "SINGLE"
              || params.data.Type == "MULTI"
              || params.data.Type == "SINGLE_BUTTONS"
              || params.data.Type == "MULTI_BUTTONS";
        },
        resizable: false,
        width: 100,
        cellRenderer: QuestionOptionsCellRenderer,
        cellEditor: QuestionOptions,
        cellEditorParams: {
            parentModel: formModel
        },
        cellEditorPopup: true,
        cellEditorPopupPosition: 'over',
        singleClickEdit: true
    },
    {
        headerName: 'Help Text',
        field: 'HelpText',
        resizable: true,
        sortable: true,
        filter: true,
        editable: true,
        autoHeight: true,
        wrapText: true,
        width: 200,
        cellRenderer: HelpTextCellRenderer,
        cellEditor: HelpTextEditor,
        cellEditorParams: {
          parentModel: formModel
        },
        cellEditorPopup: true,
        cellEditorPopupPosition: 'over',
        singleClickEdit: true
    },
    {
        headerName: 'Visible',
        field: 'Visibility',
        editable: true,
        resizable: false,
        width: 100,
        cellRenderer: VisibilityCellRenderer,
        cellEditor: Visibility,
        cellEditorParams: {
            parentModel: formModel
        },
        cellEditorPopup: true,
        cellEditorPopupPosition: 'over',
        singleClickEdit: true
    },
    {
        headerName: 'Required',
        field: 'Required',
        editable: true,
        resizable: false,
        width: 100,
        cellRenderer: RequiredCellRenderer,
        cellEditor: Required,
        cellEditorParams: {
            parentModel: formModel
        },
        cellEditorPopup: true,
        cellEditorPopupPosition: 'over',
        singleClickEdit: true
    },
    {
        headerName: 'Validation',
        field: 'Validations',
        editable: true,
        resizable: false,
        width: 100,
        cellRenderer: ValidationCellRenderer,
        cellEditor: Validations,
        cellEditorParams: {
            parentModel: formModel
        },
        cellEditorPopup: true,
        cellEditorPopupPosition: 'over',
        singleClickEdit: true
    },
    {
        headerName: 'Output',
        field: 'Interpretations',
        editable: true,
        resizable: false,
        width: 100,
        cellRenderer: InterpretationsCellRenderer,
        cellEditor: Interpretations,
        cellEditorParams: {
            parentModel: formModel
        },
        cellEditorPopup: true,
        cellEditorPopupPosition: 'over',
        singleClickEdit: true
    }
  ];

  //#region Cell Helper Functions
  const onCellEditingStarted = React.useCallback((event: any) => {
    // console.log('cellEditingStarted');
  }, []);

  const onCellEditingStopped = React.useCallback((event: any) => {
    if (event.column?.userProvidedColDef?.field == "Type") {
        if (event.oldValue != event.newValue) {
            gridRef.current?.api.refreshCells({
                force: true
            });
        }
    }
  }, [gridRef]);
  //#endregion Cell Helper Functions

  async function initiateFormModel() {
    let data;

    if (props.FormID) {
        data = await FormData.getForm(props.FormID, props.company);
    } else if (props.createNew) {
        data = await FormData.getNewForm(props.company);
    }

    setFormModel(data);
  }

  React.useEffect(() => {
    // on startup of this page, initiate pages.
    if (!formModel)
        initiateFormModel();
    else {
        var rows = getRowData();

        setTableModel(rows);
    }
  }, [formModel]);

  function saveForm(values: Form) {
    // get form to save
    var cloneToSave = mergeTableWithForm();
    
    cloneToSave.Name = values.Name;
    cloneToSave.Active = values.Active;
    cloneToSave.Published = values.Published;
    cloneToSave.Type = values.Type;
    
    FormData.setForm(cloneToSave, props.company);

    setFormModel(cloneToSave);
  }

  function mergeTableWithForm(): Form {
    if (!tableModel || !formModel) {
        throw new Error("Unable to merge nothing with something");
    }

    var cloneToSave = formModel.getClone();

    // Loop table rows and merge them with the form model to save.
    for (let i = 0; i < tableModel.length; i++) {
        if (tableModel[i].PageSummary !== undefined) {
            // Page Detail Row
            var pageNumber = tableModel[i].Page;

            // Update page summary text;
            cloneToSave.Pages[pageNumber].Summary = tableModel[i].PageSummary;
        } else {
            // Question Row
            var indexes = cloneToSave.findIndexByQuestionID(tableModel[i].ID);

            cloneToSave.Pages[indexes.Page].Questions[indexes.Question] = tableModel[i];
        }
    }

    return cloneToSave;
  }

  async function copyForm() {
    var cloneToCopy = formModel?.getClone();
    await navigator.clipboard.writeText(JSON.stringify(cloneToCopy));
    alert("Rules copied to the clipboard!");
  }

  async function pasteForm() {
    var raw = await navigator.clipboard.readText();

    toastPromise(async () => {
      var model = JSON.parse(raw) as Form;

      var cloneToSave = formModel?.getClone();

      if (cloneToSave) {
        cloneToSave.CompanyID = props.company;
        cloneToSave.Interpretations = model.Interpretations;
        cloneToSave.Pages = model.Pages;
        
        setFormModel(cloneToSave);
      }
    }, false, "Importing...", "Changes imported but not saved. Save you changes if you are sure you want to proceed.");
  }

  function addQuestion() {
    var copy = mergeTableWithForm();

    copy.addQuestion();

    setFormModel(copy);
  }

  function addPage() {
    var copy = mergeTableWithForm();

    copy.addPage();

    setFormModel(copy);
  }

  function addGenericOutput() {
    setShowOutput(true);
  }

  function stopAddingGenericOutput(newValues: any) {
    var copy = mergeTableWithForm();

    copy.Interpretations = newValues;

    setFormModel(copy);
    setShowOutput(false);
  }

  function editPageSummary(index: number) {
    setEditPageIndex(index);
    setEditPageSummaryText(formModel?.Pages[index].Summary);
  }

  function updatePage() {
      var copy = formModel?.getClone();
      
      if (copy && editPageIndex != undefined && editPageSummaryText != undefined) {
        copy.Pages[editPageIndex].Summary = editPageSummaryText;
        
        setFormModel(copy);
    }

    setEditPageIndex(undefined);
    setEditPageSummaryText(undefined);
  }

  function getRowData() {
    var rows = [];
    
    if (formModel) {
        var pages = formModel.Pages;
        
        for (var p = 0; p < pages.length; p++) {
            //add the page row that spans the whole width.
            var questions = pages[p].Questions;
            
            rows.push({
                Page: p,
                PageSummary: pages[p].Summary,
                editSummary: editPageSummary
            });
            
            for (let i = 0; i < questions.length; i++) {
                //create the row for this question.
                rows.push({
                    ...questions[i]
                })
            }
        }
    }

    return rows;
  }

  const isFullWidthRow = React.useCallback((params: any) => {
    return params.rowNode.data.PageSummary !== undefined;
  }, []);

  const getRowHeight = React.useCallback((params: any) => {
    if (params.node.data.PageSummary !== undefined) {
        return 90;
    }

    return 45
  }, [gridRef]);

  const onRowDragEnter = React.useCallback((e: any) => {
    // console.log('onRowDragEnter', e);
  }, []);

  const onRowDragEnd = React.useCallback((e: any) => {
    const copy = mergeTableWithForm();
    
    copy.relocateQuestion(e.node.data, e.overNode.data);

    setFormModel(copy);
  }, [formModel]);

  const onRowDragMove = React.useCallback((e: any) => {
    // console.log('onRowDragMove', e);
  }, []);

  const onRowDragLeave = React.useCallback((e: any) => {
    setQuestionToDelete(e.node.data);
  }, []);

  const onDragStopped = React.useCallback((e: any) => {
    if (questionToDelete && formModel) {
        var result = window.confirm(`Are you sure you want to delete the question "${questionToDelete.Text}"`);

        if (result) {
            const copy = mergeTableWithForm();

            copy.removeQuestion(questionToDelete.ID);

            setFormModel(copy);
        }

        setQuestionToDelete(undefined);
    }
  }, [formModel, questionToDelete]);

  function companyDataPointsAvailable(options: any) {
    var query = options?.query;
    var interpolations: any[] = [
      { ID: "CompanyName", Label: "Company Data - Name" },
      { ID: "CompanyEmail", Label: "Company Data - Email" },
      { ID: "CompanyWebsite", Label: "Company Data - Website" },
      { ID: "CompanyPortal", Label: "Company Data - Portal" },
      { ID: "CompanyPhone", Label: "Company Data - Phone" },
      { ID: "CompanyAddress", Label: "Company Data - Address" }
    ]

    if (query) {
        return interpolations.filter(i => i.Label.toLowerCase().startsWith(query.toLowerCase()));
    }

    return interpolations;
  }

  function renderPageEditor() {
    if (formModel === undefined || editPageIndex === undefined)
        return null;

    return (
        <div>
            <Modal
                isOpen={true}
                onRequestClose={updatePage}
                contentLabel="Example Modal"
                portalClassName="BuilderModal Required"
            >
                <div className="BuilderModalHeader">Page Summary: {formModel.Pages[editPageIndex].Number}</div>
                <div className="BuilderModalContent">
                    <TiptapCompanyReference itemResource={companyDataPointsAvailable} value={formModel.Pages[editPageIndex].Summary} setValue={(v: string) => setEditPageSummaryText(v)} />

                    <button type="button" className="btn btn-sm btn-primary" onClick={updatePage}>Save</button>
                </div>
            </Modal>
        </div>
    );
  }

  function renderTable() {
    return (
        <div style={{ height: 600, width: '100%', position: 'relative', marginTop: 10 }} className="ag-theme-balham">
            <AgGridReact
                ref={gridRef as any}
                rowData={tableModel}
                getRowHeight={getRowHeight}
                columnDefs={columnDefs as any}
                suppressRowClickSelection={true}
                enableCellTextSelection={true}
                isFullWidthRow={isFullWidthRow}
                fullWidthCellRenderer={FullWidthCellRenderer}
                reactiveCustomComponents
                onCellEditingStarted={onCellEditingStarted}
                onCellEditingStopped={onCellEditingStopped}
                rowSelection="single"
                onRowDragEnter={onRowDragEnter}
                onRowDragEnd={onRowDragEnd}
                onRowDragMove={onRowDragMove}
                onRowDragLeave={onRowDragLeave}
                onDragStopped={onDragStopped}
            >
            </AgGridReact>
        </div>
    );
  }

  function renderForm() {
    return (<>
        <div className="row">
            <FormikTools.Formik
                initialValues={formModel as any}
                validationSchema={FormOptionsSchema}
                onSubmit={values => {
                    // same shape as initial values
                    saveForm(values);
                }}
            >
            {({ errors, touched, values }) => {
                return (
                <FormikTools.Form>
                    <div className="row">
                        <div className="col-lg-5">
                            <FormikTools.Field errors={errors} name="Name" component={FormikTextInput} formiklabel="Name" formikinline="true" />
                        </div>
                        <div className="col-lg-4">
                            <FormikTools.Field errors={errors} name="Type" component={FormikSelect} values={types} formiklabel="Type" formikinline="true" />
                        </div>
                        <div className="col-lg-2">
                            <FormikTools.Field errors={errors} name="Active" component={FormikCheckBoxSingle} formiklabel="Active">
                                <Bubble helpText={"When inactivated this cannot be undone without recreating another version of this form."} width={300}></Bubble>
                            </FormikTools.Field>

                            <div className="row">
                                <div className="col-lg-12">        
                                    <FormikTools.Field errors={errors} name="Published" component={FormikCheckBoxSingle} formiklabel="Published">
                                        <Bubble helpText={"Publish this form, cannot be undone without recreating another version of this form."} width={300}></Bubble>
                                    </FormikTools.Field>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1">
                            <button className="btn btn-sm btn-primary" style={{ marginTop: 10, float: "right" }} type="submit">Save</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8">
                          {
                            props.company != "tcpSystemAdministration" ? null :
                            <>
                              <button className="btn btn-sm btn-primary" title="Copy Form" style={{ marginTop: 10, marginRight: 10, float: "left" }} type="button" onClick={copyForm}>
                                <span className="bi-clipboard"></span>
                              </button>

                              <button className="btn btn-sm btn-primary" title="Paste Form" style={{ marginTop: 10, marginRight: 10, float: "left" }} type="button" onClick={pasteForm}>
                                <span className="bi-clipboard-plus"></span>
                              </button>

                              <button className="btn btn-sm btn-primary" style={{ marginTop: 10, float: "left" }} type="button" onClick={addGenericOutput}>Add Generic Output <span className="bi-plus-lg"></span></button>
                            </>
                          }
                        </div>
                          <div className="col-lg-2">
                          {
                            props.company != "tcpSystemAdministration" ? null :
                            <button className="btn btn-sm btn-primary" style={{ marginTop: 10, float: "right" }} type="button" onClick={addPage}>Add Page <span className="bi-plus-lg"></span></button>
                          }
                          </div>
                        <div className="col-lg-2">
                          <button className="btn btn-sm btn-primary" style={{ marginTop: 10, float: "right" }} type="button" onClick={addQuestion}>Add Question <span className="bi-plus-lg"></span></button>
                        </div>
                    </div>

                    { !tableModel ? null : renderTable() }
                </FormikTools.Form>
            )}}
            </FormikTools.Formik>
        </div>

        {renderPageEditor()}

        {
          !showOutput ? null :
          <Interpretations value={formModel?.Interpretations} onValueChange={(newValue: any) => stopAddingGenericOutput(newValue)} form={formModel} />
        }
    </>);
  }

  function render() {
    return (
        <div className="container">
            { !formModel ? null : renderForm() }
        </div>
    );
  }

  return render();
}

export default FormBuilder;
