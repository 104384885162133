import React from 'react';
import { initializeApp } from '@firebase/app';
import { getFirestore, doc, writeBatch, getDoc, setDoc } from '@firebase/firestore';
import { getAuth } from "firebase/auth";
import { toastPromise } from '../Helpers/ToastMessage';
import { getFunctions, httpsCallable } from "firebase/functions";
import Bubble from '../Helpers/Bubble';
import 'react-image-upload/dist/index.css';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { BeatLoader } from 'react-spinners';

import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import {
  FormikColorSelector,
  FormikEmailInput,
  FormikImageUpload,
  FormikPasswordInput,
  FormikSelect,
  FormikTextInput,
  FormikInlineEmailInput
} from '../FormikFields';

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const db = getFirestore(app);
const functions = getFunctions();
const storage = getStorage(app, process.env.REACT_APP_FIREBASE_STORAGE_BUCKET);
const sendCompanyInviteEmail = httpsCallable(functions, "sendCompanyInviteEmail");
const cloneBaseFormsToCompany = httpsCallable(functions, "cloneBaseFormsToCompany");

function SystemCompanyOptions(props) {
  const [companyOptions, setCompanyOptions] = React.useState({
    code: props.company,
    emailChecklistStyle: "checkbox",
    emailInvitations: []
  });
  const [logo, setLogo] = React.useState();
  
  const OptionsSchema = Yup.object().shape({
    code: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    apiKey: Yup.string(),
    emailInvitations: Yup.array().of(Yup.string().email('Invalid invitation emails')),
    email: Yup.string().email('Invalid email'),
    emailKey: Yup.string(),
    emailProvider: Yup.string(),
    emailReply: Yup.string(),
    emailChecklistStyle: Yup.string().required('Required'),
    logoPath: Yup.string(),
    primaryColor: Yup.string(),
    secondaryColor: Yup.string(),
    tertiaryColor: Yup.string()
  });

  React.useEffect(() => {
    if (companyOptions.code && !props.createCompany) {
      getCompanyOptions();
    }
  }, []);

  function getCompanyOptions() {
    toastPromise(async () => {
      var c = await getDoc(doc(db, `/BusinessRules/${companyOptions.code}`));
      
      if (c && c.exists()) {
        var data = c.data();
        
        setCompanyOptions({...data, ...companyOptions});
      }
    }, true);
  }

  async function uploadFiles() {
    if (!logo || !logo.file || !logo.file.name)
      return null;
    
    const filename = `${companyOptions.code}/Logo/${logo.file.name}`;

    if (companyOptions.logoPath != filename) {   
      try {
        const logoRef = ref(storage, filename);
        const snapshot = await uploadBytes(logoRef, logo.file);

        return filename;
      } catch (e) {
        console.log(e);
        return null;
      }
    }

    return null;
  }

  function logoAdded(imageFile) {
    setLogo(imageFile);
  }

  function logoRemoved() {
    setLogo(null);
  }

  function saveForm(values) {
    if (props.createCompany) {
      createCompany(values);
    } else {
      saveOptions(values);
    }
  }

  function createCompany(companyOptions) {
    if (!companyOptions.code)
      return;
    
    toastPromise(async () => {
      var user = getAuth().currentUser;
      var batch = writeBatch(db);
      
      batch.set(doc(db, `/Companies/${companyOptions.code}`), { active: true });
      batch.set(doc(db, `/BusinessRules/${companyOptions.code}`), companyOptions);
      batch.set(doc(db, `/BusinessRules/${companyOptions.code}/Users/${user.uid}`),
        {
          Name: user.displayName,
          Email: user.email,
          Timestamp: new Date()
        }
      );

      await batch.commit();

      if (companyOptions.emailInvitations) {
        var addresses = companyOptions.emailInvitations.join(";");
        sendCompanyInviteEmail({
          company: companyOptions,
          emailAddresses: addresses,
          id: companyOptions.code
        });
      }

      cloneBaseFormsToCompany({
        id: companyOptions.code
      });

      props.finished();
    });
  }

  function saveOptions(companyOptions) {
    toastPromise(async () => {
      var data = { ...companyOptions };
      var uploadedFile = await uploadFiles();

      if (uploadedFile)
        data.logoPath = uploadedFile;

      setDoc(doc(db, `/BusinessRules/${companyOptions.code}`), data)
        .then((v) => {
          props.finished();
        });
    });
  }

  function render() {
    if (!companyOptions) {
      return (<div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div>);
    }

    return (
      <div>
        <h1>{props.createCompany ? 'Create Company' : 'Edit Company'}</h1>

        <div className="container-fluid center" style={{ maxWidth: 1000 }}>
          <Formik
            initialValues={companyOptions}
            validationSchema={OptionsSchema}
            onSubmit={values => {
              // same shape as initial values
              setCompanyOptions(values);
              saveForm(values);
            }}
          >
            {({ values, errors, touched }) => (
              <Form>
                <Field errors={errors} name="code" component={FormikTextInput} formiklabel="Company Code">
                  <Bubble helpText={"Unique identifier for this company. Must only contain letters and/or numbers."} width={300}></Bubble>
                </Field>

                <Field errors={errors} name="name" component={FormikTextInput} formiklabel="Name" />

                <FieldArray name="emailInvitations">
                  {({ insert, remove, push }) => (
                    <div style={{ position: 'relative', marginBottom: 50 }}>
                      <label className="form-label">Email Invitations:</label>

                    {
                    values.emailInvitations.map((email, index) => (
                        <div className="row" key={index}>
                          <div className="col">
                            <Field
                              name={`emailInvitations.${index}`}
                              component={FormikInlineEmailInput}
                              formiklabel="Email Invite"
                            />
                            <span className="btn btn-sm btn-danger" style={{ display: 'inline-block', marginLeft: 5 }} onClick={() => remove(index)}>
                              <span className="bi-trash"></span>
                            </span>
                          </div>
                        </div>
                      ))}

                    <div className="btn btn-sm btn-primary" style={{ position: 'absolute', right: 30 }} onClick={() => push('')}><i className='bi-plus'/> Email Invitation</div>
                  </div>
                  )}
                </FieldArray>
                {errors.emailInvitations?.length && touched.emailInvitations ? (<div style={{color: 'var(--red)'}}>{errors.emailInvitations.at(0)}</div>) : null}
                
                <Field errors={errors} name="email" component={FormikEmailInput} formiklabel="Email From Address"  />
                
                <Field errors={errors} name="emailKey" component={FormikPasswordInput} formiklabel="Email App Password">
                  <Bubble helpText={"Our application requires certain security measures in order to provide a safe and secure checklist delivery. Please follow the instructions found <a>here</a> to enter your email credentials."} width={300}></Bubble>
                </Field>

                <Field errors={errors} name="emailReply" component={FormikEmailInput} style={{ width: '100%' }} formiklabel="Email Reply Address" />

                <Field errors={errors} name="emailProvider" component={FormikSelect} formiklabel="Email Provider" values={[
                    { text: "Google", value: "gmail" },
                    { text: "Yahoo", value: "yahoo" },
                    { text: "Outlook", value: "outlook365" }
                ]} />
                
                <Field errors={errors} name="emailChecklistStyle" component={FormikSelect} formiklabel="Checklist Style" values={[
                  { text: "N/A", value: "" },
                  { text: "Bullet", value: "bullet" },
                  { text: "Checkbox", value: "checkbox" },
                  { text: "Circle", value: "circle" }
                ]} />
                
                <Field errors={errors} name="logoPath" component={FormikImageUpload} formiklabel="Logo" onFileAdded={logoAdded} onFileRemoved={logoRemoved} />

                <div className="row">
                  <div className="col-sm-4">
                    <Field errors={errors} name="primaryColor" component={FormikColorSelector} formiklabel="Primary Color" />
                  </div>
                  <div className="col-sm-4">
                    <Field errors={errors} name="secondaryColor" component={FormikColorSelector} formiklabel="Secondary Color" />
                  </div>
                  <div className="col-sm-4">
                    <Field errors={errors} name="tertiaryColor" component={FormikColorSelector} formiklabel="Tertiary Color" />
                  </div>
                </div>

                <div className="mb-3 center"> 
                  <button className="btn btn-sm btn-primary" type="submit">Save</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }

  return render();
}

export default SystemCompanyOptions;