import React from 'react';
import { useParams } from 'react-router-dom';
import { toastPromise } from './Helpers/ToastMessage';
import { initializeApp } from '@firebase/app';
import { getFirestore, getDocs, query, collection, where } from '@firebase/firestore';
import { Helmet } from 'react-helmet';

const app = initializeApp({
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  });
  
  const db = getFirestore(app);

function StaticFormLink() {
  const { ci, fl } = useParams();
  const [formlink, setFormlink] = React.useState();

  React.useEffect(() => {
    if (ci && fl && !formlink) {
      toastPromise(async ()=>{
        
        var formQuery = query(
            collection(db, `/formLink`),
            where("businessName", "==", ci),
            where("linkName", "==", fl)
        );
        var form = await getDocs(formQuery);

        if (form.docs && form.docs.length === 1) {
            const formData = form.docs[0].data();

            if (formData) {
                setFormlink(formData);
            } else {
                throw new Error("Unable to fetch form.");
            }
        } else {
          throw new Error("Unable to fetch form.");
        }
      }, true);
    }
  }, [ci, fl, formlink]);

  function render() {
    return <div>
        <Helmet>
            <meta property="og:title" content="Tax Checklist" />
            <meta property="og:type" content="website" />
            <meta property='og:image' content='https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png' />
        </Helmet>
    </div>
  }

  return render();
}

export default StaticFormLink;