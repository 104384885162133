import React from 'react';
import { useParams } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, setPersistence, browserSessionPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormikTextInput, FormikEmailInput, FormikPasswordInput } from '../FormikFields';

const functions = getFunctions();
const auth = getAuth();
const createAccountWithInvitation = httpsCallable(functions, "createAccountWithInvitation");

function AcceptInvitation() {
  const { ci, iid } = useParams();
  const [formModel, setFormModel] = React.useState({
    fullName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [validationMessage, setValidationMessage] = React.useState();
  const [signInWithExisting, setSignInWithExisting] = React.useState();

  const newAccountSchema = Yup.object().shape({
    fullName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
    confirmPassword: Yup.string()
  });

  async function createAccount(formModel) {
    if (formModel.fullName && formModel.email && formModel.password && (signInWithExisting || formModel.password === formModel.confirmPassword)) {
      await setPersistence(auth, browserSessionPersistence);
      setValidationMessage('');
      
      var cred = null;

      try {
        if (signInWithExisting) {
          cred = await signInWithEmailAndPassword(auth, formModel.email, formModel.password);
        } else {
          cred = await createUserWithEmailAndPassword(auth, formModel.email, formModel.password);
        }
        
        if (cred && cred.user) {
          const result = await createAccountWithInvitation({
            fullName: formModel.fullName,
            companyId: ci,
            invitationId: iid
          });
          
          if (!result.data.Success) {
            setValidationMessage(result.data.Message);
          } else {
            window.location.href = `/Admin/${ci}`;
          }
        } else {
          setValidationMessage("An error occurred when signing in. Please try again later.");
        }
      } catch (e) {
        setValidationMessage(e.message);
      }
    } else {
      if (!formModel.email) {
        setValidationMessage("Email is required.");
      } else if (!formModel.password) {
        setValidationMessage("Password is required and must match the confirmed password.");
      } else if (!formModel.fullName) {
        setValidationMessage("Name is required.");
      } else {
        setValidationMessage("Passwords do not match.");
      }
    }
  }

  function render() {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%' }}>
      <div className="card" style={{ 
          minWidth: "724px",
          minHeight: "547px",
          paddingLeft: 10,
          paddingRight: 10,
          backgroundColor: 'var(--bs-light-blue)'
      }}>
          <div className="card-body">
            <div className="card-title text-center" style={{ marginBottom: '43px', color: 'var(--bs-primary)', fontSize: '36px', lineHeight: '49px', fontWeight: 'bolder' }}>Accept {ci} Invitation</div>
            
            {validationMessage ? <div className="center" style={{ color: 'var(--red)' }}>{validationMessage}</div> : null}

            <div style={{ maxWidth: 600, margin: 'auto' }}>
              <Formik
                initialValues={formModel}
                validationSchema={newAccountSchema}
                onSubmit={async values => {
                  setFormModel(values);
                  createAccount(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <Field errors={errors} name="fullName" component={FormikTextInput} formiklabel="Full Name" />
                    <Field errors={errors} name="email" component={FormikEmailInput} formiklabel="Email" />
                    <Field errors={errors} name="password" component={FormikPasswordInput} formiklabel="Password" />

                    {
                      signInWithExisting ? null :
                      <Field errors={errors} name="confirmPassword" component={FormikPasswordInput} formiklabel="Confirm Password" />
                    }

                    <div className="mb-3 center">
                      <button className="btn btn-sm btn-success" type="submit">{ signInWithExisting ? "Sign In" : "Create Account" }</button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="mb-3 center"> 
                <button className="btn btn-sm btn-primary" onClick={() => setSignInWithExisting(!signInWithExisting)}>{ signInWithExisting ? "Create a new account" : "I already have an account" }</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return render();
}

export default AcceptInvitation;