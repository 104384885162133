import React from 'react';

const infoColor = "#8ACEBF";

const helpBubble = {
  position: 'absolute',
  top: -150,
  left: 30,
  borderWidth: 5,
  borderStyle: 'solid',
  borderColor: infoColor,
  borderRadius: 15,
  backgroundColor: 'white',
  zIndex: 999,
  padding: 10,
  fontWeight: 400,
  minHeight: 180
};

const helpTriangle = {
  borderLeftWidth: 10,
  borderBottomWidth: 10,
  borderTopWidth: 10,
  borderRightWidth: 20,
  borderStyle: 'solid',
  borderTopColor: 'transparent',
  borderLeftColor: 'transparent',
  borderBottomColor: 'transparent',
  borderRightColor: infoColor,
  position: 'absolute',
  left: -30,
  top: 150,
  zIndex: 1000
};

const helpIcon = {
  borderWidth: 1,
  borderColor: 'black',
  cursor: 'pointer',
  width: 25,
  height: 25,
  display: 'inline',
  position: 'relative',
  marginLeft: 5,
  color: 'black'
};

const helpLabel = {
  borderLeftWidth: 5,
  width: 85,
  height: 42,
  borderStyle: 'solid',
  borderColor: infoColor,
  borderBottomRightRadius: 10,
  borderTopLeftRadius: 10,
  backgroundColor: infoColor,
  color: '#FFF',
  position: 'absolute',
  left: 0,
  top: -5,
  zIndex: 1000,
  fontWeight: 700,
  fontSize: '19pt'
};

const helpContent = {
  textIndent: 80
}

function Bubble(props) {
  const [displayHelp, setDisplayHelp] = React.useState();

  function toggle(e) {
    e.preventDefault();
    setDisplayHelp(!displayHelp);
  }

  function renderHelp() {
    if (displayHelp) {
      return <div style={{ ...helpBubble, width: props.width }}>
        <div style={helpTriangle}></div>
        <div style={helpContent} dangerouslySetInnerHTML={{__html: props.helpText}}></div>
        <div style={helpLabel}>Help!</div>

      </div>;
    } else {
      return null;
    }
  }

  return (
    <div style={helpIcon} onClick={toggle}>
      <span className="bi-question-circle"></span>
      {renderHelp()}
    </div>
  );
}

export default Bubble;