import React from 'react';
import CreateAccount from './CreateAccount';
import {
    getAuth,
    signInWithEmailAndPassword,
    setPersistence,
    browserSessionPersistence,
    sendPasswordResetEmail
} from "firebase/auth";
import { initializeApp } from '@firebase/app';
import { getFirestore, doc, getDoc } from '@firebase/firestore';
import { useCompany, useLoginValidation } from '../Store/storeFunctions';

const auth = getAuth();

const app = initializeApp({
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});
const db = getFirestore(app);

function AdminLogin(props) {
    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState();
    const [showPassword, setShowPassword] = React.useState('password');
    const [create, setCreate] = React.useState();
    const [validCompany, setValidCompany] = React.useState();
    const company = useCompany();
    const loginValidation = useLoginValidation();

    function formLogin(e) {
        e.preventDefault();
        login();
    }

    async function companyExists() {
        var invalidCompany = false;

        if (!company.companyData?.code)
            invalidCompany = true;
        else {
            try {
                var c = await getDoc(doc(db, `/Companies/${company.companyData?.code}`));
                
                if (c && c.exists()) {
                    var data = c.data();
                    
                    if (data && data.active) {
                        // console.log("valid");
                    } else {
                        invalidCompany = true;
                        loginValidation.set("Invalid Company Code.");
                    }
                } else {
                    invalidCompany = true;
                    loginValidation.set("Invalid Company Code.");
                }
            } catch (ex) {
                console.error(ex);
                loginValidation.set("Invalid Company Code: error.");
            }
        }

        if (invalidCompany) {
            setTimeout(() => {
                window.location = "/Admin";
            }, 3000);
        }

        setValidCompany(!invalidCompany);
    }

    React.useEffect(() => {
        companyExists();
    }, []);

    async function login() {
        if (email && password) {
            await setPersistence(auth, browserSessionPersistence)
                .then(() => {
                    return signInWithEmailAndPassword(auth, email, password);
                })
                .catch((error) => {
                    switch (error.code) {
                        case 'auth/too-many-requests':
                            loginValidation.set("Too many failed attempts.");
                            break;
                        case 'auth/wrong-password':
                        case 'auth/user-not-found':
                            loginValidation.set("Invalid username/password.");
                            break;
                        default:
                            loginValidation.set(error.code);
                            break;
                    }
                    console.error(error);
                });
        }
    }

    const triggerResetEmail = async () => {
        if (email) {
            try {
                await sendPasswordResetEmail(auth, email);
                loginValidation.set("Password reset email sent!");
            } catch (e) {
                loginValidation.set(e.message);
            }
        } else {
            loginValidation.set("Email is required to reset your password!");
        }
    }

    function cancelCreate() {
        setCreate(false);
    }

    function render() {
        if (create) {
            return <CreateAccount cancel={cancelCreate} />
        }
        return (
            <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%' }}>
                    <div className="card" style={{ 
                        minWidth: "724px",
                        minHeight: "547px",
                        paddingLeft: 10,
                        paddingRight: 10,
                        backgroundColor: 'var(--bs-light-blue)'
                    }}>
                        <div className="card-body">
                            <div className="card-title text-center" style={{ marginBottom: '43px', color: 'var(--bs-primary)', fontSize: '36px', lineHeight: '49px', fontWeight: 'bolder' }}>Sign In</div>
                            <form className="form-horizontal" style={{ width: '50%', margin: 'auto' }} onSubmit={formLogin}>
                                {loginValidation.error ? <p style={{ color: 'var(--red)' }}>{loginValidation.error}</p> : null}
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="email">Email</label>
                                    <input type="email" className="form-control form-control-sm" id="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Enter your email" />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label" htmlFor="pwd">Password</label>
                                    
                                    <div className="input-group mb-3" style={props.formikinline ? { width: '65%', display: 'inline-block', marginLeft: 5 } : {}}>
                                      <input type={showPassword} className="form-control form-control-sm" id="pwd" value={password} onChange={e => setPassword(e.target.value)} placeholder="Enter your password" />
                                      
                                      <button className="btn btn-sm btn-primary reveal" type="button" onClick={() => {
                                          if (showPassword === "password") {
                                              setShowPassword("text");
                                          } else {
                                              setShowPassword("password");
                                          }
                                      }}>{showPassword === "password" ? <i className="bi-eye"></i> : <i className="bi-eye-slash"></i>}</button>
                                  </div>
                                </div>

                                <div className="col center mt-5">
                                    <button className="btn btn-sm btn-secondary" type="submit" disabled={!validCompany} style={{ width: '256px', 'height': '50px' }}>Login</button>
                                </div>
                                <div className="col center mt-5">
                                    {
                                        !validCompany ? null :
                                        <a className="primary" role="button" onClick={triggerResetEmail}>Forgot Your Password?</a>
                                    }
                                </div>
                                <div className="mb-3 row">
                                    {/* <div className="col center">
                                        <button className="btn btn-sm btn-secondary" onClick={() => setCreate(true)}>Register</button>
                                    </div> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return render();
}

export default AdminLogin;