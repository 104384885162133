import React from "react";
import Modal from "react-modal";

Modal.setAppElement('#root');

interface ResendProps {
  resendCustom: (email: string) => void,
  resendAdmin: () => void,
  resendUser: () => void,
  close: () => void,
  userEmail: string
}

export default (props: ResendProps) => {
  const [tempEmail, setTempEmail] = React.useState('');

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={() => props.close()}
        contentLabel="Resend Submission"
        portalClassName="BuilderModal Interpretations"
        style={{ }}
      >
        <div className="BuilderModalHeader">Resend Submission(s)</div>
        <div className="BuilderModalContent">
          <div style={styles.actionInputArea}>
            <label className="control-label" htmlFor="email">Custom Email</label>
            <input type="email" className="form-control form-control-sm" style={styles.actionInput} id="email" value={tempEmail} onChange={e => setTempEmail(e.target.value)} placeholder="Enter email" />
          </div>

          <div style={styles.actionButtonArea}>
            <button className="btn btn-primary" style={styles.actionButton} onClick={() => props.resendCustom(tempEmail)} title={`Re-Send to ${tempEmail || ""}`}>Send to Custom</button>
            <button className="btn btn-primary" style={styles.actionButton} onClick={() => props.resendAdmin()} title={`Re-Send to ${props.userEmail || "Me"}`}>Re-Send to Admin</button>
            <button className="btn btn-primary" style={styles.actionButton} onClick={() => props.resendUser()} title={`Re-Send to Client Email`}>Re-Send to Client</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const styles = {
  filters: {
    backgroundColor: '#84C5FB',
    borderRadius: 10
  },
  actionInputArea: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 10,
    display: 'flex',
    gap: 10
  },
  actionInput: {
    display: 'inline-block',
    width: '80%',
  },
  actionButton: {
    minWidth: 180,
    margin: 10
  },
  actionButtonArea: {
    width: "80%",
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    'flex-wrap': 'wrap'
  }
}