import React from 'react';
import { useEditor, EditorContent, BubbleMenu, ReactRenderer } from '@tiptap/react';
import Mention from '@tiptap/extension-mention';
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image';
import tippy from 'tippy.js';
import EmailMentionList from './EmailMentionList';

const TiptapEmailTemplate = (props) => {
  const editor = useEditor({
    extensions: [
        Document,
        Paragraph,
        Text,
        Image,
        Underline,
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
          defaultAlignment: 'left'
        }),
        Mention.configure({
            HTMLAttributes: {
                class: 'email_template_reference', //do not change without full understanding of our processor
            },
            renderText({ options, node }) {
                return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
            },
            renderHTML({ options, node }) {
                return [
                    "span",
                    {
                        "data-id": node.attrs.id,
                        "data-label": node.attrs.label,
                        "data-type": "mention",
                        class: "email_template_reference"
                    },
                    `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
                ];
            },
            suggestion: {
                char: '@',
                decorationClass: 'interpretation_item',
                items: props.itemResource,
                render: renderMention
            }
        }),
    ],
    content: props.value,
    onUpdate: updateParent,
  }, [props.itemResource]);

  function updateParent ({ editor }) {
    props.setValue(editor.getHTML());
  }

  function renderMention () {
    let component
    let popup

    return {
        onStart: props => {
            component = new ReactRenderer(EmailMentionList, {
                props,
                editor: props.editor,
            })

            if (!props.clientRect) {
                return
            }

            popup = tippy('body', {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
            })
        },

        onUpdate(props) {
            component.updateProps(props)

            if (!props.clientRect) {
                return
            }

            popup[0].setProps({
                getReferenceClientRect: props.clientRect,
            })
        },

        onKeyDown(props) {
            if (props.event.key === 'Escape') {
                popup[0].hide()

                return true
            }

            return component.ref?.onKeyDown(props)
        },

        onExit() {
            popup[0].destroy()
            component.destroy()
        },
    }
  }
  
  const addImage = React.useCallback(() => {
    const url = window.prompt('URL')

    if (url) {
      editor.chain().focus().setImage({ src: url }).run()
    }
  }, [editor])

  if (!editor) {
    return null
  }

  if (!editor) {
    return null
  }

  return (<>        
        <div className='EmailTipTapEditor'>
          <div className='EmailTipTapEditorHeader'>
            <div className='btn-group'>
              <button type="button"
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={
                  !editor.can()
                    .chain()
                    .focus()
                    .toggleBold()
                    .run()
                }
                className={`btn ${editor.isActive('bold') ? 'btn-primary' : 'btn-secondary'}`}
              >
                bold
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={
                  !editor.can()
                    .chain()
                    .focus()
                    .toggleItalic()
                    .run()
                }
                className={`btn ${editor.isActive('italic') ? 'btn-primary' : 'btn-secondary'}`}
              >
                italic
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={
                  !editor.can()
                    .chain()
                    .focus()
                    .toggleStrike()
                    .run()
                }
                className={`btn ${editor.isActive('strike') ? 'btn-primary' : 'btn-secondary'}`}
              >
                strike
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                className={`btn ${editor.isActive('underline') ? 'btn-primary' : 'btn-secondary'}`}
              >
                underline
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={`btn ${editor.isActive('paragraph') ? 'btn-primary' : 'btn-secondary'}`}
              >
                paragraph
              </button>
            </div>

            <div className="btn-group">
              <button type="button"
                onClick={() => editor.chain().focus().setTextAlign('left').run()}
                className={`btn ${editor.isActive({ textAlign: 'left' }) ? 'btn-primary' : 'btn-secondary'}`}
              >
                Left
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().setTextAlign('center').run()}
                className={`btn ${editor.isActive({ textAlign: 'center' }) ? 'btn-primary' : 'btn-secondary'}`}
              >
                Center
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().setTextAlign('right').run()}
                className={`btn ${editor.isActive({ textAlign: 'right' }) ? 'btn-primary' : 'btn-secondary'}`}
              >
                Right
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().setTextAlign('justify').run()}
                className={`btn ${editor.isActive({ textAlign: 'justify' }) ? 'btn-primary' : 'btn-secondary'}`}
              >
                Justify
              </button>
              <button type="button" onClick={() => editor.chain().focus().unsetTextAlign().run()} className='btn btn-secondary'>Unset text align</button>
            </div>

            <div className='btn-group'>
              <button type="button"
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={`btn ${editor.isActive('heading', { level: 1 }) ? 'btn-primary' : 'btn-secondary'}`}
              >
                h1
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={`btn ${editor.isActive('heading', { level: 2 }) ? 'btn-primary' : 'btn-secondary'}`}
              >
                h2
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                className={`btn ${editor.isActive('heading', { level: 3 }) ? 'btn-primary' : 'btn-secondary'}`}
              >
                h3
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
                className={`btn ${editor.isActive('heading', { level: 4 }) ? 'btn-primary' : 'btn-secondary'}`}
              >
                h4
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
                className={`btn ${editor.isActive('heading', { level: 5 }) ? 'btn-primary' : 'btn-secondary'}`}
              >
                h5
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
                className={`btn ${editor.isActive('heading', { level: 6 }) ? 'btn-primary' : 'btn-secondary'}`}
              >
                h6
              </button>
            </div>

            <div className='btn-group'>
              <button type="button"
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={`btn ${editor.isActive('bulletList') ? 'btn-primary' : 'btn-secondary'}`}
              >
                bullet list
              </button>
              <button type="button"
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={`btn ${editor.isActive('orderedList') ? 'btn-primary' : 'btn-secondary'}`}
              >
                ordered list
              </button>
            </div>
            
            <div className='btn-group'>
              <button type="button" className="btn btn-secondary" onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                line
              </button>
              <button type="button" className="btn btn-secondary" onClick={() => editor.chain().focus().setHardBreak().run()}>
                break
              </button>
            </div>

            <div className='btn-group'>
              <button type="button" className="btn btn-secondary"
                onClick={() => editor.chain().focus().undo().run()}
                disabled={
                  !editor.can()
                    .chain()
                    .focus()
                    .undo()
                    .run()
                }
              >
                undo
              </button>
              <button type="button" className="btn btn-secondary"
                onClick={() => editor.chain().focus().redo().run()}
                disabled={
                  !editor.can()
                    .chain()
                    .focus()
                    .redo()
                    .run()
                }
              >
                redo
              </button>
              <button type="button" className="btn btn-secondary" onClick={addImage}>add image</button>
            </div>
          </div>

          <EditorContent editor={editor} />
        </div>
    </>)
}

export default TiptapEmailTemplate;