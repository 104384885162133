import React from "react";
import { Option } from '../Form';

interface QuestionOptionsElement {
    value: Option,
    buttons: boolean,
    questionResource: (options?: any) => any[],
    setValue: (newValue: Option) => void,
    remove: () => void
}

export default (props: QuestionOptionsElement) => {
    function updateModel(newValue: string) {
      var copy = new Option(newValue, props.value.Icon);

      props.setValue(copy);
    }

    function updateIcon(newValue: string) {
      var copy = new Option(props.value.Value, newValue);

      props.setValue(copy);
    }

    function renderIcon() {
      if (!props.buttons) {
        return null;
      } else {
        return <span style={{ marginLeft: 20 }}>Icon: 
            <input value={props.value.Icon} onChange={v => updateIcon(v.target.value)}></input>
            <i className={`bi-${props.value.Icon}`} style={{ marginLeft: 10 }}></i>
          </span>
      }
    }

    return (
        <div className="ReferenceEquation">
            <button className="bi-dash-circle danger btn btn-sm btn-danger" onClick={props.remove}></button>
            Text: <input value={props.value.Value} onChange={v => updateModel(v.target.value)}></input>

            { renderIcon() }
        </div>
    );
}