import React from 'react';

function SubmissionDetails(props) {
  function getBusinessLegalFromAnswers() {
    const list = props.Submission.Questions.filter(q => q.Text == "Business Legal name");

    if (list && list.length == 1)
      return list[0].Answer;
    else
      return "-N/A-";
  }

  function getNameFromAnswers() {
    const businessName = getBusinessLegalFromAnswers();

    if (businessName && businessName !== "-N/A-")
      return businessName;
    
    const list = props.Submission.Questions.filter(q => q.Text == "First Name" || q.Text == "Last Name");

    if (list && list.length == 2) {
      var str = " ";

      list.forEach(element => {
        if (element.Text == "First Name") {
          str = element.Answer + str;
        } else {
          str = str + element.Answer;
        }
      });

      return str;
    } else {
      const fullName = props.Submission.Questions.filter(q => q.Text == "Name of Submitter");

      if (fullName && fullName.length == 1) {
        return fullName[0].Answer;
      } else {
        return "-N/A-";
      }
    }
  }

  function getEmailFromAnswers() {
    const list = props.Submission.Questions.filter(q => q.Text == "Email");

    if (list && list.length == 1)
      return list[0].Answer;
    else
      return "-N/A-";
  }

  function getQuestionsAndAnswers() {
    var answered = props.Submission.Questions.filter(q => q.Answer);

    return answered.map((q, i) => {
      return <>
        <b>{q.Text}</b>
        <p>{q.Answer.toString()}</p>
        { i < answered.length - 1 ? <hr style={{ marginTop: 8, marginBottom: 8 }} /> : null }
      </>
    })
  }

  function getDocs() {
    if (props.Submission.Documents.length == 0) {
      return <p>None</p>
    }

    return props.Submission.Documents.map((d, i) => {
      return <>
        <p dangerouslySetInnerHTML={{ __html: d }}></p>
        { i < props.Submission.Documents.length - 1 ? <hr style={{ marginTop: 8, marginBottom: 8 }} /> : null }
      </>
    })
  }

  function getLastUpdateTime() {
    if (props.Submission.LastStatusUpdate) {
      return props.Submission.LastStatusUpdate.toDate().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      });
    } else {
      return props.Submission.Timestamp.toDate().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric"
      });
    }
  }

  function getStatus() {
    if (!props.usesStatus) {
      return null;
    }
    
    var statusColor = {};

    if (props.Submission.Status == "Complete") {
      statusColor.color = "var(--green)";
    } else {
      statusColor.color = "var(--yellow)";
    }

    return <span><span style={{ ...statusColor }}>{props.Submission.Status}</span> - </span>;
  }

  function render() {
    return (
      <div className="container" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div className="row" style={{ height: 200 }}>
          <h1>{getNameFromAnswers()} - {getEmailFromAnswers()}</h1>
          <h5>{props.Submission.FormTitle}</h5>
          <h4>{getStatus()}{getLastUpdateTime()}</h4>
        </div>

        <div className="row" style={{ height: 'calc(100% - 200px)' }}>
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button className="nav-link active" id="nav-qa-tab" data-bs-toggle="tab" data-bs-target="#nav-qa" type="button" role="tab" aria-controls="nav-qa" aria-selected="true">Answers</button>
              <button className="nav-link" id="nav-docs-tab" data-bs-toggle="tab" data-bs-target="#nav-docs" type="button" role="tab" aria-controls="nav-docs" aria-selected="false">Required Documents</button>
            </div>
          </nav>

          <div className="tab-content" id="nav-tabContent" style={{ padding: 20, paddingBottom: 0, overflowY: 'scroll', height: 'calc(100% - 50px)' }}>
            <div className="tab-pane fade show active" id="nav-qa" role="tabpanel" aria-labelledby="nav-qa-tab">{getQuestionsAndAnswers()}</div>
            <div className="tab-pane fade" id="nav-docs" role="tabpanel" aria-labelledby="nav-docs-tab">{getDocs()}</div>
          </div>
        </div>
      </div>
    );
  }

  return render();
}

const styles = {
}

export default SubmissionDetails;