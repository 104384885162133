import React from "react";
import Modal from "react-modal";
import TiptapCompanyReference from "./TipTapCompanyReference";

Modal.setAppElement('#root');

export default (props: any) => {
    const [model, setModel] = React.useState<string>(props.value);

    function updateQuestion() {
        props.onValueChange(model);
        props.stopEditing();
    }

    function companyDataPointsAvailable(options: any) {
      var query = options?.query;
      var interpolations: any[] = [
        { ID: "CompanyName", Label: "Company Data - Name" },
        { ID: "CompanyEmail", Label: "Company Data - Email" },
        { ID: "CompanyWebsite", Label: "Company Data - Website" },
        { ID: "CompanyPortal", Label: "Company Data - Portal" },
        { ID: "CompanyPhone", Label: "Company Data - Phone" },
        { ID: "CompanyAddress", Label: "Company Data - Address" }
      ]
  
      if (query) {
          return interpolations.filter(i => i.Label.toLowerCase().startsWith(query.toLowerCase()));
      }
  
      return interpolations;
    }

    return (
        <div>
            <Modal
                isOpen={true}
                onRequestClose={updateQuestion}
                contentLabel="Example Modal"
                portalClassName="BuilderModal Interpretations"
            >
                <div className="BuilderModalHeader">Question Text</div>
                <div className="BuilderModalContent">
                  <TiptapCompanyReference itemResource={companyDataPointsAvailable} value={model} setValue={(v: string) => setModel(v)} />

                  <button type="button" className="btn btn-sm btn-primary" onClick={updateQuestion}>Save</button>
                </div>
            </Modal>
        </div>
    );
}