import React from 'react';
import { initializeApp } from '@firebase/app';
import { getFirestore, getDoc, setDoc, doc, query, collection, where, getDocs } from '@firebase/firestore';
import { toastPromise } from '../Helpers/ToastMessage';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Bubble from '../Helpers/Bubble.js';
import { BeatLoader } from 'react-spinners';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
    FormikEmailInput,
    FormikSelect,
    FormikCheckBoxSingle,
    FormikTextInput,
} from '../FormikFields';
import TiptapEmailTemplate from '../Form/BuilderTools/TipTapEmailTemplate';
import { useCompany } from '../Store/storeFunctions';

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const db = getFirestore(app);
const storage = getStorage(app, process.env.REACT_APP_FIREBASE_STORAGE_BUCKET);

function EmailEditor(props) {
  const [emailTemplate, setEmailTemplate] = React.useState("<p></p>");
  const [logoUrl, setLogoUrl] = React.useState();
  const [settings, setSettings] = React.useState();
  const [types, setTypes] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [selectedType, setSelectedType] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState();

  const company = useCompany();

  const EmailOptionsSchema = Yup.object().shape({
      ccEmail: Yup.boolean(),
      ccEmailAddress: Yup.string(),
      answersEmailAddress: Yup.string(),
      emailSubject: Yup.string().required('Required'),
      logoUrl: Yup.string(),
      active: Yup.boolean(),
      type: Yup.string().required('Required')
  });

  function saveEmailTemplate(settings) {
    if (props.FormID) {
      toastPromise(async () => {
        if (!settings.formName) {
          var type = types.find(o => o.value == settings.type);

          if (type.text) {
            settings.formName = type.text;
          }
        }

        // query for currently active forms
        var q = query(
          collection(db, `/formLink`),
          where("businessName", '==', props.company),
          where("active", "==", true),
          where("type", "==", settings.type)
        );
        
        var actives = await getDocs(q);

        // update this form.
        await setDoc(
          doc(db, `/formLink/${props.FormID}`),
          {
            emailTemplate: emailTemplate,
            emailSubject: settings.emailSubject,
            ccEmail: settings.ccEmail,
            ccEmailAddress: settings.ccEmailAddress,
            answersEmailAddress: settings.answersEmailAddress,
            type: settings.type,
            active: settings.active,
            formName: settings.formName
          },
          { merge: true }
        );

        // inactivate other forms that were active. Should always be one but loop to be sure.
        if (settings.active && actives && actives.docs && actives.docs.length > 0) {
          for (let i = 0; i < actives.docs.length; i++) {
            var id = actives.docs[i].id;

            if (id != props.FormID) {
              await setDoc(
                doc(db, `/formLink/${id}`),
                { active: false },
                { merge: true }
              );
            }
          }
        }
      });
    }

    props.back();
  }

  function getEmailData() {
    toastPromise(
      async () => {
        getDoc(doc(db, `/formLink/${props.FormID}`)).then(doc => {
          if (doc && doc.exists()) {
            var link = doc.data();
            var emailSettings = {...settings};

            emailSettings.type = link.type ?? "";
            emailSettings.active = link.active ?? false;
            emailSettings.ccEmailAddress = link.ccEmailAddress ?? "";
            emailSettings.answersEmailAddress = link.answersEmailAddress ?? "";
            emailSettings.ccEmail = link.ccEmail ?? false;
            emailSettings.emailSubject = link.emailSubject ?? "";
            emailSettings.emailTemplate = link.emailTemplate ?? "<p></p>";

            //set type based on subject if it doesn't exist
            if (!emailSettings.type && emailSettings.emailSubject) {
              if (emailSettings.emailSubject.indexOf("Business") == 0)
                emailSettings.type = "BUSINESS";
              else if (emailSettings.emailSubject.indexOf("Onboarding") == 0)
                emailSettings.type = "ONBOARDING_INTAKE";
              else if (emailSettings.emailSubject.indexOf("Payroll") == 0)
                emailSettings.type = "PAYROLL_REVIEW";
              else
                emailSettings.type = "INDIVIDUAL";
            }

            setEmailTemplate(emailSettings.emailTemplate);
            setSettings(emailSettings);
            setSelectedType(emailSettings.type);
          }
        });
      },
      true
    );
  }

  function getLogoUrl() {
    toastPromise(async () => {
      if (company.companyData.logoPath) {
        const logoRef = ref(storage, company.companyData.logoPath);
        getDownloadURL(logoRef).then(url => {
          setLogoUrl(url);
        }).catch(ex => {
          console.error("No logo found.");
        });
      }
    }, true);
  }

  function getTypes() {
    toastPromise(async () => {
      var c = await getDoc(doc(db, `/SystemFormTypes/AllTypes`));
      
      if (c && c.exists()) {
        var data = c.data();

        if (data) {
          var keys = Object.keys(data);
          var typeArray = [];

          keys.forEach(key => {
            typeArray.push({
              value: key,
              text: data[key]
            });
          });

          setTypes(typeArray);
        }
      }
    }, true);
  }

  React.useEffect(() => {
    getEmailData();
    getLogoUrl();
    getTypes();
  }, []);

  React.useEffect(() => {
    if (selectedType) {
      setQuestionsForInterpolations();
    }
  }, [selectedType]);

  function setQuestionsForInterpolations() {
    toastPromise(async () => {
      var q = query(
        collection(db, `/SystemForms`),
        where("Type", '==', selectedType),
        where("Active", "==", true)
      );
      
      var forms = await getDocs(q);
      
      if (forms && forms.docs?.length > 0) {
        var items = [];
        var data = forms.docs[0].data();

        if (data) {
          data.Pages.forEach(page => {
            page.Questions.forEach(question => {
              items.push({
                ID: question.ID,
                Label: question.Text
              });
            });
          });

          setQuestions(items);
        }
      }
    }, true);
  }

  var interpolationList = React.useCallback((options) => {
    var query = options?.query;

    var interpolations = [
        { ID: "Documents", Label: "Documents" }, // Required for system to run
        { ID: "SubmittedButton", Label: "Submitted Button" }, // Required for docs are submitted button
        { ID: "StatusLink", Label: "Status Link" }, // Required for docs are submitted button
        { ID: "CompanyName", Label: "Company Data - Name" },
        { ID: "CompanyEmail", Label: "Company Data - Email" },
        { ID: "CompanyWebsite", Label: "Company Data - Website" },
        { ID: "CompanyPortal", Label: "Company Data - Portal" },
        { ID: "CompanyPhone", Label: "Company Data - Phone" },
        { ID: "CompanyAddress", Label: "Company Data - Address" },
        ...questions
    ];

    if (query) {
        return interpolations.filter(i => i.Label.toLowerCase().startsWith(query.toLowerCase()));
    }

    return interpolations;
  }, [questions]);

  return (
    <>
    {!settings ? <div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div> :
    <div>
      <div className="container">
        <Formik
          initialValues={settings}
          validationSchema={EmailOptionsSchema}
          onSubmit={values => {
            // same shape as initial values
            setErrorMessage("");
            const pattern = "<span data-id=\"Documents\" data-label=\"Documents\" data-type=\"mention\" class=\"email_template_reference\">@Documents</span>";
            const includedDocs = emailTemplate.includes(pattern);

            if (includedDocs) {
              setSettings(values);
              saveEmailTemplate(values);
            } else {
              setErrorMessage("@Documents is not included in the email template.");
            }
          }}
        >
          {({ errors, touched, values }) => {
            if (selectedType != values.type) {
              setSelectedType(values.type);
            }

            return <Form>
              <div className="row">
                <div className="col-sm-4">
                  <Field errors={errors} name="type" component={FormikSelect} values={types} formiklabel="Type" />
                  
                  <Field errors={errors} name="emailSubject" component={FormikTextInput} formiklabel="Subject">
                    <Bubble helpText={"To reference answers to questions in the subject line, just surround the question name with {}. Ex: {name} {taxYear}"} width={300}></Bubble>
                  </Field>

                  <Field errors={errors} name="active" component={FormikCheckBoxSingle} formiklabel="Active">
                    <Bubble helpText={"Turn on/off this questionnaire."} width={300}></Bubble>
                  </Field>

                  <Field errors={errors} name="ccEmail" component={FormikCheckBoxSingle} formiklabel="CC Email">
                    <Bubble helpText={"Turn on/off the ability to receive a copy of the client checklist."} width={300}></Bubble>
                  </Field>

                  {
                    !values.ccEmail ? null :
                    <Field errors={errors} name="ccEmailAddress" component={FormikEmailInput} style={{ width: '100%' }} formiklabel="CC Email Address">
                      <Bubble helpText={"The email address that you want a copy of the client checklist to be sent to."} width={300}></Bubble>
                    </Field>
                  }

                  <Field errors={errors} name="answersEmailAddress" component={FormikEmailInput} style={{ width: '100%' }} formiklabel="Answer Receiving Email">
                    <Bubble helpText={"The email address that you want a copy of the form submission answers to be sent to."} width={300}></Bubble>
                  </Field>
                </div>
                
                <div className="col-sm-8">
                  <div style={styles.help}>
                    <h5>Helpful Tips</h5>

                    <p style={styles.helpText}>Use the <strong>{"@"}</strong> symbol to refer to data from the submission, status references, or your own company data. When used correcly it will be outlined with a thin black border.</p>
                    
                    <p style={styles.helpText}><strong>@Documents</strong> must be included in the location you want the documents list to be inserted.</p>
                    <p style={styles.helpText}><strong>@Submitted Button</strong> may be included in the location you want the 'finished submitting my documents' button to appear within the result email. <i>Status feature must be turned on for this to be of use.</i></p>
                    <p style={styles.helpText}><strong>@Status Link</strong> may be included in the location you want a link to the result screen with the status bar to appear. <i>Status feature must be turned on for this to be of use.</i></p>

                    <p style={styles.helpText}>Your logo is not included by default. To add your logo to the email template make sure it is uploaded on the 'Company Options' page. You can then click 'Copy Logo URL' below and use it with the 'add image' button in the editor. Paste the url into the box that appears and it will be added to the email template.</p>
                  </div>
                </div>
              </div>
              

              <div className="mb-3">
                <label className="form-label">
                  Template:
                  <Bubble helpText={"This is where you can customize the content of the checklist email your client will receive upon completing the deduction questionnaire. You can add your logo, link your secure portal, and clarify which email you would like your clients to send their documents to."} width={300}></Bubble>
                  {errorMessage ? <div className="center" style={{ color: 'var(--red)' }}>{errorMessage}</div> : null}
                </label>
                
                {
                  !interpolationList.length ? null :
                  <TiptapEmailTemplate itemResource={interpolationList} value={emailTemplate} setValue={(v) => setEmailTemplate(v)} />
                }
              </div>

              <div className="mb-3" style={{float: "right"}}>
                {
                  !logoUrl ? null :
                  <button className="btn btn-sm btn-primary" onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(logoUrl);
                    alert("Logo Url: " + logoUrl);
                  }}>Show Logo URL</button>
                }

                <button className="btn btn-sm btn-primary" style={{ marginLeft: 10 }} type="submit">Save</button>
              </div>
            </Form>
          }}
        </Formik>
      </div>
    </div> }
    </>
  );
}

const styles = {
  help: {
    borderRadius: 10,
    backgroundColor: '#E6F5F1',
    width: '100%',
    height: 'auto',
    padding: 30
  },
  helpText: {
    fontFamily: 'Open Sans, Light',
    fontSize: 16,

  }
}

export default EmailEditor;