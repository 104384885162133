import React from 'react';
import { initializeApp } from '@firebase/app';
import { getFirestore, doc, getDoc } from '@firebase/firestore';

const app = initializeApp({
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const db = getFirestore(app);

function CompanyCode(props) {
    const [company, setCompany] = React.useState();
    const [validationMessage, setValidationMessage] = React.useState();

    async function companyExists(e) {
        e.preventDefault();

        var c = await getDoc(doc(db, `/Companies/${company}`));

        if (c && c.exists()) {
            var data = c.data();

            if (data && data.active && props.goToLogin) {
                window.location = "/Admin/" + company;
            } else {
                setValidationMessage('Company is not active.');
            }
        } else {
            setValidationMessage('Company does not exist.');
        }
    }

    function render() {
        return (
            <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%' }}>
                    <div className="card" style={{ 
                        minWidth: "724px",
                        minHeight: "547px",
                        paddingLeft: 10,
                        paddingRight: 10,
                        backgroundColor: 'var(--bs-light-blue)'
                    }}>
                        <div className="card-body">
                            <div className="card-title text-center" style={{ marginBottom: '43px', color: 'var(--bs-primary)', fontSize: '36px', lineHeight: '49px', fontWeight: 'bolder' }}>Sign In</div>
                            
                            {validationMessage ? <div className="center" style={{ color: 'var(--red)' }}>{validationMessage}</div> : null}

                            <form className="form-horizontal" style={{ width: '50%', margin: 'auto' }} onSubmit={companyExists}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="companyCode">Company Code</label>
                                    <input type="text" className="form-control form-control-sm" id="companycode" value={company} aria-describedby="companyhelp" onChange={(v) => setCompany(v.target.value)} />
                                    <div id="companyhelp" className="form-text">Company specific login code.</div>
                                </div>

                                <div className="col center mt-5">
                                    <button className="btn btn-sm btn-secondary" type="submit" to={`/Admin/${company}`} style={{ width: '256px', 'height': '50px' }}>Verify & Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return render();
}

export default CompanyCode;