import React from 'react';
import { initializeApp } from '@firebase/app';
import { getFirestore, getDocs, query, where, collection, Timestamp } from '@firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import DateTimePicker from 'react-datetime-picker';
import Bubble from '../Helpers/Bubble.js';
import { toastPromise } from '../Helpers/ToastMessage';
import { useUser } from '../Store/storeFunctions';
import { AgGridReact } from 'ag-grid-react';
import { YesNoCellRenderer } from '../Helpers/AgGridCellRenderers';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

const db = getFirestore(app);
const functions = getFunctions();
const processSubmission = httpsCallable(functions, "resendSubmissionEmail");

function SystemSubmissions(props) {
  const [processed, setProcessed] = React.useState("NO");
  const [submissions, setSubmissions] = React.useState();
  const [tempEmail, setTempEmail] = React.useState();
  const [testFromDate, setTestFromDate] = React.useState(getInitialFromDate());
  const [testToDate, setTestToDate] = React.useState(getInitialToDate());
  const gridRef = React.useRef();
  const user = useUser();

  function getInitialFromDate() {
    var d = new Date();
    d.setDate(d.getDate() - 7);
    d.setHours(0, 0, 0);
    return d;
  }

  function getInitialToDate() {
    var d = new Date();
    d.setHours(23, 59, 59);
    return d;
  }

  const processedOptions = [
    'YES',
    'NO',
    'ALL'
  ]

  const columnDefs = [
    {
      field: 'name',
      valueGetter: (params) => {
        return getNameFromAnswers(params.data.Answers);
      },
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'FormTitle',
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'email',
      valueGetter: (params) => {
        return getEmailFromAnswers(params.data.Answers);
      },
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'Processed',
      valueGetter: (params) => {
        if (params.data.Processed) {
          return "YES";
        } else {
          return "NO";
        }
      },
      cellRenderer: YesNoCellRenderer,
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'Timestamp',
      valueGetter: (params) => {
        return params.data.Timestamp.toDate().toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        })
      },
      resizable: true,
      sortable: true
    }
  ];

  React.useEffect(() => {
    getCompanySubmissions();
  }, []);

  function getTimestampWithoutTimezone(value) {
    // TODO: Later - dates are currently being stored with the timezone.
    // change this back when we are done storing dates without timezone.
    // const date = new Date(value);
    // const offset = date.getTimezoneOffset() * 60000;
    // const normal = new Date(date.getTime() + offset);
    return Timestamp.fromDate(value);
  }

  function getCompanySubmissions(e) {
    if (e)
      e.preventDefault();
    
    toastPromise(
      async () => { 
        if (testFromDate && testToDate) {
          const from = getTimestampWithoutTimezone(testFromDate);
          const to = getTimestampWithoutTimezone(testToDate);

          var subs = [];
          let submissionQuery;

          if (processed !== "ALL") {
            submissionQuery = query(
              collection(db, `/BusinessRules/${props.company}/Submissions`),
              where('Processed', "==", processed === "YES"),
              where('Timestamp', ">=", from),
              where('Timestamp', "<=", to)
              );
          } else {
            submissionQuery = query(
              collection(db, `/BusinessRules/${props.company}/Submissions`),
              where('Timestamp', ">=", from),
              where('Timestamp', "<=", to)
              );
          }

          var submissionDocs = await getDocs(submissionQuery);

          submissionDocs.forEach((doc) => {
            if (doc.exists()) {
              var sub = 
              {
                id: doc.id,
                ...doc.data()
              };

              if (sub.Answers) {
                sub.name = getNameFromAnswers(sub.Answers);
                sub.email = getEmailFromAnswers(sub.Answers);
              } else {
                sub.name = "";
                sub.email = "";
              }

              subs.push(sub);
            }
          });

          setSubmissions(subs);
        }
      },
      true
    );
  }

  async function resendEmail() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      toastPromise(
        async ()=>{
          for (let i = 0; i < rows.length; i++) {
            var row = rows[i];

            var res = await processSubmission({
              submissionID: row.id,
              formID: row.FormID,
              companyID: props.company
            });
            
            if (!res.data.success) {
              throw 'Email did not send.';
            }
          }
        },
        false,
        `Sending...`,
        `Emails Sent!`
      );
    }
  }

  async function resendAdminEmail() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      toastPromise(
        async ()=>{
          for (let i = 0; i < rows.length; i++) {
            var row = rows[i];
            
            var res = await processSubmission({
              submissionID: row.id,
              formID: row.FormID,
              companyID: props.company,
              to: user.user.email
            });

            if (!res.data.success) {
              throw 'Email did not send.';
            }
          }
        },
        false,
        `Sending to ${user.user.email}...`,
        `Email Sent to ${user.user.email}!`
      );
    }
  }

  async function resendNewEmail() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      if (tempEmail) {
        toastPromise(
          async ()=>{
            for (let i = 0; i < rows.length; i++) {
              var row = rows[i];
              
              var res = await processSubmission({
                submissionID: row.id,
                formID: row.FormID,
                companyID: props.company,
                to: tempEmail
              });

              if (!res.data.success) {
                throw 'Email did not send.';
              }
            }
          },
          false,
          `Sending to ${tempEmail}...`,
          `Email Sent to ${tempEmail}!`
        );
      } else {
        alert("Custom email must be entered in order to 'Send to Custom'. Please fill in the Custom Email field and try again.");
      }
    }
  }

  function getBusinessLegalFromAnswers(answers) {
    const rgxName = /Business Legal Name:([^,]*)/;
    const match = answers.match(rgxName);

    if (match && match[1])
      return match[1];
    else
      return "-N/A-";
  }

  function getNameFromAnswers(answers) {
    const businessName = getBusinessLegalFromAnswers(answers);

    if (businessName && businessName !== "-N/A-")
      return businessName;
    
    const rgxName = /Name:([^,]*)/;
    const match = answers.match(rgxName);

    if (match && match[1])
      return match[1];
    else
      return "-N/A-";
  }

  function getEmailFromAnswers(answers) {
    const rgxEmail = /Email:([^,]*)/;
    const match = answers.match(rgxEmail);

    if (match && match[1])
      return match[1];
    else
      return "-N/A-";
  }

  function renderFilters () {
    return (
      <div className="container">
        <form className="row" onSubmit={getCompanySubmissions}>
          <div className="col-8">
              <DateTimePicker onChange={setTestFromDate} value={testFromDate} disableClock={true} clearIcon={null} />
              <Bubble helpText={"From is used as the minimum date and time allowed. Any submission before this time will not be included."} width={300}></Bubble>
              &nbsp;-&nbsp;
              <DateTimePicker onChange={setTestToDate} value={testToDate} disableClock={true} clearIcon={null} />
              <Bubble helpText={"To is used as the maximum date and time allowed. Any submission after this time will not be included."} width={300}></Bubble>
          </div>

          <div className="col-4">
            Processed:&nbsp;
            <select className="form-select form-select-sm" style={{display: "inline", width: 75}} value={processed} onChange={v=>setProcessed(v.target.value)}>
              {processedOptions.map(o => <option value={o}>{o}</option>)}
            </select>
            <Bubble helpText={"Processed is set when an email has successfully been sent. If there was an issue sending the original this will be set to NO. ALL will show those that have and have not been processed."} width={300}></Bubble>
            
            <button className="btn btn-sm btn-primary" style={{float: 'right'}} type="submit">Search</button>
          </div>
        </form>
      </div>
    );
  }

  function render() {
    return (
      <div className="container" style={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '2vh' }}>
        <div className="row">
          <div className="col-9">
            <div style={styles.filters}>
              {renderFilters()}
            </div>

            <div style={{ height: 500, width: '100%', position: 'relative', marginTop: 12 }} className="ag-theme-balham">
              <AgGridReact
                ref={gridRef}
                rowData={submissions}
                columnDefs={columnDefs}
                suppressRowClickSelection={true}
                enableCellTextSelection={true}
                rowSelection="multiple">
              </AgGridReact>
            </div>
          </div>

          <div className="col-3" style={styles.actions}>
            <label className="control-label" htmlFor="email" style={{ marginRight: 5 }}>Custom Email</label>
            <input type="email" className="form-control form-control-sm"  style={styles.actionInput} id="email" value={tempEmail} onChange={e => setTempEmail(e.target.value)} placeholder="Enter email"/>
          
            <button className="btn btn-primary" style={styles.actionButton} onClick={()=>resendNewEmail()} title={`Re-Send to ${tempEmail || ""}`}>Send to Custom</button>
            <button className="btn btn-primary" style={styles.actionButton} onClick={()=>resendAdminEmail()} title={`Re-Send to ${user.user.email || ""}`}>Re-Send to Admin</button>
            <button className="btn btn-primary" style={styles.actionButton} onClick={()=>resendEmail()} title={`Re-Send to Client Email`}>Re-Send to Client</button>
          </div>
        </div>
      </div>
    );
  }

  return render();
}

const styles = {
  filters: {
    backgroundColor: '#84C5FB',
    borderRadius: 10
  },
  actions: {
    border: '1px solid #A7A7A7',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  actionButton: {
    width: '80%',
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  actionInput: {
    width: '80%',
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}

export default SystemSubmissions;